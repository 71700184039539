import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk for removing a course from the cart
export const removeCourseFromCart = createAsyncThunk(
  'cart/removeCourseFromCart',
  async ({ courseId, isEnterprise }, { rejectWithValue }) => {
    try {
      const Token = Cookies.get('authToken');  // Retrieve auth token from cookies

      if (!Token) {
        return rejectWithValue('Authentication token is missing');
      }

      // Sending POST request with course_id in the body
      const response = await axios.post(
        `${URL}/user-courses-cdelete`,  // Endpoint for removing course
        {  
          course_id: courseId,
          is_enterprise: isEnterprise , 
        
        }, // Send the course_id in the request body       // Request body with course_id
        {
          headers: {
            token: Token,  // Include the token in the request header
          }, 
        }
      );

      // If the response contains updated courses, return them
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || 'Error removing course from cart';
      return rejectWithValue(errorMessage);
    }
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    adding: false,
    removing: false,  // State to track removal in progress
    error: null,
    success: false,
    courseData: [],  // Default to an empty array to avoid null checks
  },
  reducers: {
    resetCartState(state) {
      state.adding = false;
      state.removing = false;
      state.error = null;
      state.success = false;
      state.courseData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeCourseFromCart.pending, (state) => {
        state.removing = true;
        state.error = null;
        state.success = false;
      })
      .addCase(removeCourseFromCart.fulfilled, (state, action) => {
        state.removing = false;
        state.success = true;
        state.error = null;

        // Check if response contains updatedCourses, otherwise keep an empty array
        state.courseData = action.payload.data?.updatedCourses || [];  
      })
      .addCase(removeCourseFromCart.rejected, (state, action) => {
        state.removing = false;
        state.success = false;
        state.error = action.payload || 'Error removing course from cart';  // Set error message
      });
  },
});

export const { resetCartState } = cartSlice.actions;

export default cartSlice.reducer;

// instructorCoursesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk to fetch instructor courses
export const fetchInstructorCourses = createAsyncThunk(
  'instructor/fetchInstructorCourses',
  async (isArchived, { rejectWithValue }) => {
    try {
      // Get the instructor token from cookies
      const token = Cookies.get('Instructor_Token');

      // Make a GET request to fetch courses
      const response = await axios.post(`${URL}/instructor/courses`, { is_archived : isArchived},{
        headers: {
          token: token, // Use token as the header, consistent with previous components
        },
      });

      console.log(response.data); // Check the received data
      return response.data.data.courses; // Return the courses array on success
    } catch (err) {
      console.log('error', err.response.data);
      return rejectWithValue(err.response ? err.response.data : 'An error occurred');
    }
  }
);

// Create a slice for instructor courses
const instructorCoursesSlice = createSlice({
  name: 'instructorCourses',
  initialState: {
    courses: [],    // Store the array of courses
    loading: false, // Handle loading state
    error: null,    // Handle error state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructorCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInstructorCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload; // Store the fetched courses
      })
      .addCase(fetchInstructorCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error if fetching fails
      });
  },
});

export default instructorCoursesSlice.reducer;

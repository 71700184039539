// courseUnarchiveSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk to unarchive a course
export const unarchiveCourse = createAsyncThunk(
  'course/unarchiveCourse',
  async (courseId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('Instructor_Token');
      const response = await axios.get(`${URL}/instructor/courses/${courseId}/unarchive`, {
        headers: {
          token: token,
        },
      });

      if (response.data.status) {
        return {
          course: response.data.data.course,
          message: response.data.msg,
        };
      } else {
        return rejectWithValue({
          message: response.data.msg,
        });
      }
    } catch (err) {
      console.log('error', err.response?.data || err);
      return rejectWithValue({
        message: err.response?.data?.msg || 'An error occurred',
      });
    }
  }
);

// Create a slice for course unarchival
const courseUnarchiveSlice = createSlice({
  name: 'courseUnarchive',
  initialState: {
    unarchivedCourse: null, // Store the updated course
    loading: false,         // Handle loading state
    error: null,            // Handle error state
    message: '',            // Add message state to store success or error messages
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Unarchive Course
      .addCase(unarchiveCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = '';
      })
      .addCase(unarchiveCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.unarchivedCourse = action.payload.course; // Update the unarchived course
        state.message = action.payload.message; // Set success message
      })
      .addCase(unarchiveCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message; // Store the error message
        state.message = action.payload.message; // Set error message
      });
  },
});

export default courseUnarchiveSlice.reducer;

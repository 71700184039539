import React, { useState } from 'react';
import './Timecourse.css'; // You can add your styles here

import DeleteIcon from '../../assets/images/DeleteCourse.svg'; // Import the delete icon

const TimeComponent = ({ times, setTimes }) => {
  const [newTime, setNewTime] = useState('');

  const handleInputChange = (e) => {
    setNewTime(e.target.value);
  };

  const handleAddTime = (event) => {
    event.preventDefault();
    if (newTime.trim() && times.length < 3) {
      setTimes((prevTimes) => [...prevTimes, newTime]);
      setNewTime('');
    }
  };

  const handleRemoveTime = (index) => {
    setTimes((prevTimes) => prevTimes.filter((_, i) => i !== index));
  };

  return (
    <div className="time-container">
      <div className="InstructorCreateCourse_form_Section_title"> Times</div>
      <div className="time-form">
        <input
          type="datetime-local"
          name="time"
          value={newTime}
          onChange={handleInputChange}
          className="time-input"
        />
        <div className="time-component-container">
          <button onClick={(e) => handleAddTime(e)} className='Enterprise_Next_Button'>
            + Add Time
          </button>
        </div>
      </div>
      <ul className="time-list">
        {times.map((time, index) => (
          <li key={index} className="time-item">
            <div>
              <strong>Time {index + 1}:</strong> {time}
            </div>
            <img
              src={DeleteIcon}
              onClick={() => handleRemoveTime(index)}
              className="time-delete-icon"
              alt="Delete"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TimeComponent;

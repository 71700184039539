import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import {URL} from '../../config'; // Adjust the path to your config file

// Async thunk to fetch instructors
export const fetchEnterpriseInstructors = createAsyncThunk(
  'enterprise/fetchInstructors',
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get('entoken'); // Get token from cookies
      const response = await axios.get(`${URL}/enterprise/all-instructors`, {
        headers: {
          token
        },
      });
      return response.data.data.instructors; // Return the instructors array
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || 'Failed to fetch instructors.'
      );
    }
  }
);

// Slice definition
const enterpriseInstructorsSlice = createSlice({
  name: 'enterpriseInstructors',
  initialState: {
    instructors: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnterpriseInstructors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEnterpriseInstructors.fulfilled, (state, action) => {
        state.loading = false;
        state.instructors = action.payload;
      })
      .addCase(fetchEnterpriseInstructors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default enterpriseInstructorsSlice.reducer;

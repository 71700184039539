import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '../../../assets/images/instructors/add.svg';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import ArticleDashboard from '../../../component/ArticleDashboard/ArticleDashboard';
import Line from '../../../assets/images/Line.svg'

const InstructorArticles = () => {
  return (
    <div className='Article_Show_component'>
      <div >
        <div style={{paddingBottom:'50px'}}>
          <Link to='/create-Article' className='link'>
            <button className='InstructorHome_AddCourse_Button'>
              <img src={AddIcon} alt='AddTheIcon' className='me-2' />
              Create New Article
            </button>
          </Link>
        </div>
      </div>
   
      <div className='Courses_Instructor_Container'>
        {/* Iterate through the courses array to render CourseDashboard components dynamically */}
        <div className='Line_instractor_Dashboard'>
           <img src={Line} alt='Line' />
       </div>
          <div style={{marginTop:'50px'}}>
            <div></div>
            <ArticleDashboard ArchiveValue={0}/>
          </div>
          
      </div>
    </div>
  );
};

export default InstructorArticles;

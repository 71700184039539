import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';
import Cookies from 'js-cookie';

// Async thunk لجلب بيانات المستخدم
export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken'); // استرجاع الـ token من الـ cookies
      const response = await axios.get(`${URL}/profile`, {
        headers: {
          token: token,
        },
      });
      return response.data; // إرجاع البيانات في حالة النجاح
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch user data');
    }
  }
);

// إنشاء Slice
const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    loading: false,
    error: null,
    userData: null,
  },
  reducers: {
    resetUserData: (state) => {
      // إعادة تعيين حالة البيانات
      state.loading = false;
      state.error = null;
      state.userData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload; // تخزين بيانات المستخدم
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // رسالة الخطأ
      });
  },
});

export const { resetUserData } = userDataSlice.actions;
export default userDataSlice.reducer;

import React, { useEffect, useRef , useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLanguage } from "../../context/LanguageContext";
import './enterpriseInformationForm.css';
import RichTextEditor from "../../component/RichTextEditor/RichTextEditor";
import Saveicon from '../../assets/images/instructors/save.svg'
import { fetchEnterpriseProfile } from '../../store/enterpriseFeature/profileEnterpriseSlice';
import { updateEnterpriseProfile } from '../../store/enterpriseFeature/updateEnterpriseProfileSlice';
import { motion } from 'framer-motion';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import DOMPurify from 'dompurify';
import  { REACT_APP_GOOGLE_MAPS_API_KEY }  from '../../config'
const libraries = ["places"]; // لاستخدام المكتبة العادية بدون advanced markers


const EnterpriseInformationForm = () => {
  
   const dispatch = useDispatch()

   const { profile } = useSelector((state) => state.enterpriseProfile);
   const Enterpricedata = profile?.data?.enterprise ;
   console.log(Enterpricedata)
   {/* want when navigate to this route get all profile data   */}

   const { loading, success, error } = useSelector(
    (state) => state.updateEnterpriseProfile
  );




  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchEnterpriseProfile());
      } catch (error) {
        console.error("Error fetching enterprise profile:", error);
      }
    };
  
    fetchData();
  }, [dispatch]);


  const { language } = useLanguage();
  const [formData, setFormData] = useState({
    owner_name: "",
    name: "",
    email: "",
    phone: "",
    website_link: "", // Added website field
    description: "",
    specialization: "",
    address: "",
    license_image: '',
    lat: '',
    lng: '',
  });



  useEffect(() => {
    if (Enterpricedata?.license_image) {
      setPreviewImage(Enterpricedata.license_image);
      setFormData((prevState) => ({
        ...prevState,
        license_image: Enterpricedata.license_image,
      }));
    }
  }, [Enterpricedata]);

  const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const checkIfAllFieldsArePopulated = (data) => {
    return (
      !!data.name?.trim() &&
      !!data.email?.trim() &&
      !!data.phone?.trim() &&
      !!data.website_link?.trim() && // تأكد أن الحقل موجود
      !!stripHTML(data.about_details)?.trim() &&
      !!data.specialization?.trim() &&
      !!data.address?.trim()
    );
  };

  useEffect(() => {
    // If profile data is available, update the form data
    if (Enterpricedata) {
      setFormData({
        owner_name: Enterpricedata.owner_name || "",
        name: Enterpricedata.name || "",
        email: Enterpricedata.email || "",
        phone: Enterpricedata.phone || "",
        website_link: Enterpricedata.website_link || "", // Ensure website field is populated
        description: Enterpricedata.about_details || "",
        specialization: Enterpricedata.specialization || "",
        address: Enterpricedata.address || "",
        license_image: Enterpricedata.license_image , // Clear any existing image before uploading a new one
        lat: Enterpricedata.lat,
        lng: Enterpricedata.lng,
      });

      if (Enterpricedata) {
        console.log("Enterpricedata available:", Enterpricedata);
    
        const allFieldsPopulated = checkIfAllFieldsArePopulated(Enterpricedata);
        console.log("3m meso", allFieldsPopulated);
    
        if (allFieldsPopulated) {
          localStorage.removeItem('userStatus');
        } else {
          localStorage.setItem('userStatus', 'newuser');
        }
      } else {
        console.log("Enterpricedata not available yet");
      }


    }
  }, [Enterpricedata]); // This hook will trigger when Enterpricedata changes
    
 





 // map and define lat and lng 
 const [markerPosition, setMarkerPosition] = useState({
  lat: Enterpricedata?.lat || 0,
  lng: Enterpricedata?.lng || 0,
});

  const [accreditation, setAccreditation] = useState(null); // For file storage
  const [previewImage, setPreviewImage] = useState(null); // For previewing the image

  const [images, setImages] = useState([]); // For additional images
  const [previewImages, setPreviewImages] = useState([]); // For previewing additional images
  const [mainImage, setMainImage] = useState(previewImages[0] || null);

 console.log('TestImages' ,mainImage)



 const handleFileChange = (e) => {
  const file = e.target.files ? e.target.files[0] : null;

  // التعامل مع رفع صورة جديدة
  if (file) {
    if (file.size > 2 * 1024 * 1024) {
      alert("حجم الملف يجب أن يكون أقل من 2 ميجابايت.");
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      license_image: file,
    }));
    setPreviewImage(URL.createObjectURL(file));
  } 
  // استخدام الصورة الموجودة مسبقًا
  else if (Enterpricedata && Enterpricedata.license_image) {
    setFormData((prevState) => ({
      ...prevState,
      license_image: Enterpricedata.license_image,
    }));
    setPreviewImage(Enterpricedata.license_image); // رابط الصورة الموجودة
  } 
  // في حالة عدم وجود صورة
  else {
    alert("لم يتم العثور على صورة الترخيص!");
  }
};

/*
const handleQuillChange = (value) => {
  const sanitizedValue = DOMPurify.sanitize(value);  // تنظيف النص
  setFormData((prevState) => ({
    ...prevState,
    description: sanitizedValue,
  }));
};
*/

const handleQuillChange = (value) => {
  // تحقق مما إذا كانت القيمة نصية
  if (typeof value === 'string') {
    setFormData((prevState) => ({
      ...prevState,
      description: value,
    }));
  } else {
    // إذا كانت القيمة كائنًا، قم بإظهار رسالة خطأ أو معالجة البيانات بطريقة مختلفة
    console.error('Data is not a valid string:', value);
  }
};

  const translations = {
    en: {
      title: "Enterprise Information Form",
      ownerFirstName: "Owner First Name",
      ownerLastName: "Owner Last Name",
      enterpriseName: "Enterprise Name",
      email: "Email",
      phone: "Phone",
      website: "Website", // Added website translation
      about: "About",
      enterpriseTitle: "Enterprise Title",
      address: "Enterprise address",
      accreditation: "Accreditation",
      images: "Enterprise Images",
      selectImages: "Select up to 5 images",
      submit: "Save data",
      successMessage:'The Form Save successfully',
      placeholder: {
        ownerFirstName: "Enter owner's first name",
        ownerLastName: "Enter owner's last name",
        enterpriseName: "Enter enterprise name",
        email: "Enter email",
        phone: "+02135165666",
        website: "Enter enterprise website", // Added website placeholder
        about: "Write something about the enterprise",
        enterpriseTitle: "Enter enterprise title",
        address: "Enter enterprise address",
      },
    },
    ar: {
      title: "نموذج معلومات الاكادميه",
      ownerFirstName: "الاسم الأول للمالك",
      ownerLastName: "اسم العائلة للمالك",
      enterpriseName: "اسم المؤسسة",
      email: "البريد الإلكتروني",
      phone: "رقم الهاتف",
      website: "الموقع الإلكتروني", // Added website translation
      about: "نبذة عن المؤسسة",
      enterpriseTitle: "عنوان المؤسسة",
      address: "مجال المؤسسة",
      accreditation: "شهادة الاعتماد",
      images: "صور المؤسسة",
      selectImages: "اختر حتى 5 صور",
      submit: "حفظ البيانات",
      placeholder: {
        ownerFirstName: "أدخل الاسم الأول للمالك",
        ownerLastName: "أدخل اسم العائلة للمالك",
        enterpriseName: "أدخل اسم المؤسسة",
        email: "أدخل البريد الإلكتروني",
        phone: "+02135165666",
        website: "أدخل موقع المؤسسة", // Added website placeholder
        about: "اكتب نبذة عن المؤسسة",
        enterpriseTitle: "أدخل عنوان المؤسسة",
        address: "أدخل عنوان المؤسسة",
      },
    },
  };

  const mapRef = useRef(null);
  console.log("Test Map : ",markerPosition.lng)
  const onLoad = useCallback((map) => {
    mapRef.current = map;
  // إنشاء Marker عادي قابل للسحب
  const marker = new window.google.maps.Marker({
    position: markerPosition,
    map: mapRef.current,
    draggable: true,
  });

    // إضافة Listener لتحديث الإحداثيات بعد السحب
    marker.addListener('dragend', (event) => {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
      console.log("New Position:", newPosition );
    });
  }, [markerPosition]);
  // Function to convert FormData to a plain object
  const formDataToObject = (formData) => {
    const obj = {};
    for (const [key, value] of formData.entries()) {
      // If the key already exists, convert it to an array
      if (obj.hasOwnProperty(key)) {
        if (!Array.isArray(obj[key])) {
          obj[key] = [obj[key]];
        }
        obj[key].push(value);
      } else {
        obj[key] = value;
      }
    }
    return obj;
  };

  const handleImagesChange = (event) => {
    const files = Array.from(event.target.files);
    if (!files || files.length === 0) return;
  
    // تخزين الملفات الجديدة
    setImages((prevImages) => [...prevImages, ...files]);
  
    // إنشاء روابط للمعاينة
    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setPreviewImages((prevImages) => [...prevImages, ...imageUrls]);
  
    // تعيين الصورة الأولى كصورة رئيسية إذا لم تكن موجودة
    if (!mainImage && imageUrls.length > 0) {
      setMainImage(imageUrls[0]);
    }
  
    // تنظيف الروابط عند الإزالة لتجنب تسريب الذاكرة
    return () => {
      imageUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  };
  



  const handleMainImageSwap = (index) => {
    const newMainImage = previewImages[index];
    setMainImage(newMainImage);

    // Rearrange the images
    const updatedImages = [...previewImages];
    updatedImages.splice(index, 1);
    updatedImages.unshift(newMainImage);
    setPreviewImages(updatedImages);
  };
  const removeImage = (index) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
    setPreviewImages((prev) => prev.filter((_, i) => i !== index));
  
    // Update main image if the removed image was the main one
    if (index === 0 && previewImages.length > 1) {
      setMainImage(previewImages[1]); // Set the next image as main
    } else if (previewImages.length === 1) {
      setMainImage(null); // No images left
    }
  };

  const texts = translations[language];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    


    images.forEach((image , index) => {
      formDataToSend.append('images_enterprise[]', image);
    });
    

     console.log(formDataToSend)
        try {
      // Use unwrap() to await the Promise and handle resolved/rejected states
      await dispatch(updateEnterpriseProfile(formDataToSend)).unwrap();
      //alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    }
  };
  
  
  return (
    <div className={`EnterpriseInformation_Container ${language}`} dir={language === 'ar' ? 'rtl' : 'ltr'}>
      <h2 className="EnterpriseInformation_textTitle">{texts.title}</h2>
      <form onSubmit={handleSubmit}>
        <div className="EnterpriseInformation_Section">
          <label>{texts.ownerFirstName} <span className="required">*</span></label>
          <input
            type="text"
            name="owner_name"
            placeholder={texts.placeholder.ownerFirstName}
            value={formData.owner_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.enterpriseName} <span className="required">*</span></label>
          <input
            type="text"
            name="name"
            placeholder={texts.placeholder.enterpriseName}
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.email} <span className="required">*</span></label>
          <input
            type="email"
            name="email"
            placeholder={texts.placeholder.email}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.phone} <span className="required">*</span></label>
          <input
            type="tel"
            name="phone"
            placeholder={texts.placeholder.phone}
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.website}</label> {/* Added website field */}
          <input
            type="url"
            name="website_link"
            placeholder={texts.placeholder.website}
            value={formData.website_link}
            onChange={handleChange}
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.about}</label>
          <RichTextEditor
             value={typeof formData.description === 'string' ? formData.description : ''} // تحقق من أن القيمة نصية
             onChange={handleQuillChange}
          />
        </div>

        <div className="EnterpriseInformation_Section" style={{marginTop:'25px'}}>
      <label>Location at google map </label>
         <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
          <GoogleMap
           mapContainerStyle={{ height: "400px", width: "100%" }}
           center={markerPosition}
           zoom={10}
           onLoad={onLoad}
          />
          </LoadScript>
    </div>




        <div className="EnterpriseInformation_Section">
          <label>{texts.enterpriseTitle} <span className="required">*</span></label>
          <input
            type="text"
            name="specialization"
            placeholder={texts.placeholder.enterpriseTitle}
            value={formData.specialization}
            onChange={handleChange}
            required
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.address} <span className="required">*</span></label>
          <input
            type="text"
            name="address"
            placeholder={texts.placeholder.address}
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.accreditation} <span className="required">*</span></label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
        
          />
             <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%'}}>
               {previewImage && <img src={previewImage} alt="Preview" style={{maxWidth:'350px'}}/>}
            </div>
        </div>



        <div className="EnterpriseInformation_Section">
      <label>{texts.images}</label>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImagesChange}
      />
      <div className="image-preview">
        {mainImage && (
          <motion.div
            className="main-image"
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src={mainImage} alt="Main Preview"  />
          </motion.div>
        )}
        <motion.div
          className="thumbnails"
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {previewImages.map((image, index) => (
            <motion.div
              key={index}
              className="thumbnail"
              layout
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => handleMainImageSwap(index)}
            >
              <img src={image} alt={`Preview ${index}`} />
         
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>

    <div className="EnterpriseInformation_Section_Button">
  <button type="submit" disabled={loading}>
    {loading ? (
      <>
        <span className="spinner"></span> {/* يمكن استخدام CSS لتصميم الـ Spinner */}
        {texts.loading} {/* رسالة التحميل */}
      </>
    ) : (
      <>
        <img src={Saveicon} alt="Saveicon" />
        {texts.submit}
      </>
    )}
  </button>

  {/* عرض رسائل الخطأ أو النجاح */}
  {success && (
    <p className="success-message" style={{ color: "green" }}>
      {texts.successMessage || "تم الحفظ بنجاح!"}
    </p>
  )}
  {error && (
    <p className="success-message" style={{ color: "red" }}>
      {error || error.msg}
    </p>
  )}
</div>
      
      </form>
    </div>
  );
};

export default EnterpriseInformationForm;

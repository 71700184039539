import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'; // Import js-cookie for token retrieval
import axios from 'axios'; // Import axios for API calls
import { URL } from '../../config'; // Import the config file for the base URL

// Async thunk to fetch all instructors
export const userFetchAllInstructors = createAsyncThunk(
  'user/getAllInstructors',
  async (_, { rejectWithValue }) => {
    try {
      // Retrieve the auth token from cookies
      const token = Cookies.get('authToken');
      if (!token) {
        throw new Error('Authentication token not found'); // Handle missing token
      }

      // API call to fetch all instructors
      const response = await axios.get(`${URL}/all-instructor`, {
        headers: {
          token, // Include the token in the Authorization header
        },
      });

      // Extract the instructors from the response
      return response.data.data.instructors;
    } catch (error) {
      // Return a rejected value with an error message
      return rejectWithValue(
        error.response?.data?.msg || error.message || 'Something went wrong'
      );
    }
  }
);

// Initial state for the slice
const initialState = {
  instructors: [], // Store the list of instructors
  loading: false,  // Indicates whether the request is in progress
  error: null,     // Stores error messages, if any
};

// Create the slice
const userGetAllInstructorsSlice = createSlice({
  name: 'userGetAllInstructors',
  initialState,
  reducers: {}, // No additional reducers needed for this slice
  extraReducers: (builder) => {
    builder
      .addCase(userFetchAllInstructors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userFetchAllInstructors.fulfilled, (state, action) => {
        state.loading = false;
        state.instructors = action.payload; // Update the instructors list
      })
      .addCase(userFetchAllInstructors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      });
  },
});

// Export the reducer to add it to the store
export default userGetAllInstructorsSlice.reducer;

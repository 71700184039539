import React from 'react';
import Line from '../../../assets/images/Line.svg'
//import Certificate from '../../../assets/images/instructors/certicate.png'
const AboutInstructor = ({ profile }) => {
  // Clean the profile image URL
  let profile_image = profile?.instructor?.profile_image;
  if (profile_image.includes('http://dash.lndex.io/uploads/instructors_images/http://')) {
    profile_image = profile_image.replace('http://dash.lndex.io/uploads/instructors_images/', '');
  }



  const about_details = profile?.instructor?.about_details || 'No details available';

  return (
    <div>
    
      <div className='Line_instractor_Dashboard'>

      <img src={Line} alt='Line'  />
      </div>


      <div className='About_container'>
        
      <div className='About_container_Description'>
          {/* عرض المحتوى بالتنسيقات باستخدام dangerouslySetInnerHTML */}
          <div dangerouslySetInnerHTML={{ __html: about_details }} />
        </div>
        {/* Instructor Image */}
    {/*    <div className='About_container_Image'>
          <img className='bb' src={Certificate} alt='instructor_profile' style={{width:'25vw'}} onError={(e) => e.target.src = '/path/to/default_image.jpg'} />
        </div> */}
      </div>
    </div>
  );
};

export default AboutInstructor;
/*
SECURITY ABOUT TEXT EDITOR 

dangerouslySetInnerHTML 

import React from "react";
import DOMPurify from "dompurify";

export default function App() {
  const htmlContent = "<p>This is raw <strong>HTML</strong> content.<p>";
  const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);
  return (
    <div className="App">
      <h1>Raw HTML</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}></div>
    </div>
  );
}
  1-12-2024
*/
// File: src/store/features/enterpriseForgetPasswordSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';

// Async thunk for forget password
export const forgetEnterprisePassword = createAsyncThunk(
  'enterprise/forgetPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/enterprise/forget-password`, { email });
      return response.data; // Response is expected to contain { status, msg, data }
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { status: false, msg: 'Something went wrong' }
      );
    }
  }
);

const enterpriseForgetPasswordSlice = createSlice({
  name: 'enterpriseForgetPassword',
  initialState: {
    loading: false,
    msg: null,
    error: null,
    success: false, // Add success field
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.msg = null;
      state.error = null;
      state.success = false; // Reset success
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgetEnterprisePassword.pending, (state) => {
        state.loading = true;
        state.msg = null;
        state.error = null;
        state.success = false; // Reset success on pending
      })
      .addCase(forgetEnterprisePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.msg = action.payload.msg;
        state.error = null;
        state.success = true; // Set success on fulfilled
      })
      .addCase(forgetEnterprisePassword.rejected, (state, action) => {
        state.loading = false;
        state.msg = null;
        state.error = action.payload?.msg || 'An unexpected error occurred';
        state.success = false; // Set success to false on error
      });
  },
});


// Exporting the resetState action
export const { resetState } = enterpriseForgetPasswordSlice.actions;

// Exporting the reducer to be added to the store
export default enterpriseForgetPasswordSlice.reducer;

import React, { useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEnterpriseInstructors } from '../../store/enterpriseFeature/instructorEnterpriseSlice';

const InstructorDropdown = ({ selectedInstructor, onInstructorChange, language = 'en' }) => {
  const dispatch = useDispatch();

  const { instructors, loading, error } = useSelector(
    (state) => state.enterpriseInstructors
  );

  useEffect(() => {
    // Fetch instructors when the component mounts
    dispatch(fetchEnterpriseInstructors());
  }, [dispatch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Formatting the instructors list for React-Select
  const options = instructors.map((instructor) => ({
    value: instructor.id,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' , fontSize:'20px'}}>
        <img
          src={instructor.profile_image}
          alt="Instructor"
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            marginRight: '10px',
          }}
        />
       Mr/Mrs {instructor.name}
      </div>
    ),
  }));

  const handleChange = (selectedOption) => {
    onInstructorChange(selectedOption.value);
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      placeholder={
        language === 'en' ? 'Select the instructor' : 'اختر المدرب'
      }
      value={options.find((opt) => opt.value === selectedInstructor)}
      classNamePrefix="custom-select"
      styles={{
        control: (base) => ({
          ...base,
          borderRadius: '8px',
          border: 'none',
          backgroundColor: '#EDEFFF',
          height: '60px',
        }),
        menu: (base) => ({
          ...base,
          borderRadius: '8px',
          zIndex: 10,
        }),
      }}
    />
  );
};

export default InstructorDropdown;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk to update course data
export const updateCourse = createAsyncThunk(
  'instructor/updateCourse',
  async ({ courseId, courseData }, { rejectWithValue }) => {
    try {
      const token = Cookies.get('Instructor_Token');
      console.log("Course ID:", courseId);
      console.log("Course Data:", courseData);
      const response = await axios.post(`${URL}/instructor/courses/update/${courseId}`, courseData, {
        headers: {
          token: token,
        },
      });

      return response.data.data;
    } catch (err) {
        console.error('Error message:', err.message);
        if (err.response) {
          console.error('Response data:', err.response.data);
          console.error('Response status:', err.response.status);
        }
      return rejectWithValue(err.response ? err.response.data : 'An error occurred');
    }
  }
);

// Slice for updating the course
const instructorUpdateCourseSlice = createSlice({
  name: 'instructorUpdateCourse',
  initialState: {
    course: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        console.log("Update successful:", action.payload); // Debugging log
        state.loading = false;
        state.course = action.payload;
      })
      .addCase(updateCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default instructorUpdateCourseSlice.reducer;

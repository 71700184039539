import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import EnterPriseNav from '../../component/EnterpriseNav/EnterpriseNav'
import Footer from '../../component/Footer/Footer'

const EnterpriseLayout = () => {
  const [isNewUser, setIsNewUser] = useState(false);
  const navigate = useNavigate();


  {/* 
  useEffect(() => {
    // Check if the user is new from localStorage
    const userStatus = localStorage.getItem('userStatus');
    if (userStatus === 'newuser') {
      setIsNewUser(true);
    } else {
      setIsNewUser(true);
      navigate('/enterprise/home'); // Redirect to 'home' if not new user
    }
  }, [navigate]);
*/}
  return (
    <div>
      {/* Enterprise Header */}
      <header>
        <EnterPriseNav />
      </header>

      {/* Dynamic Content */}
      <main>
        {isNewUser ? (
          // If it's a new user, show the Information Form
          <Outlet />
        ) : (
          // Otherwise, navigate to home
          <Outlet />
        )}
      </main>
     
      <Footer />
    </div>
  );
}

export default EnterpriseLayout;

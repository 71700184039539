import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Loading from './component/Loading/Loading'; // Import the loading component

// Import all your pages
import Blog from './pages/Blog/index';
import Home from './pages/Home/index';
//import IndexInstructor from './pages/InstructorPage/index';
import CartPage from './pages/Cart/index';
import SearchPage from './pages/SearchPage/index';
import CourseDetails from './pages/CourseDetails/index';
import ArticleById from './pages/ArticleById/index';
import LoginPage from './pages/LoginPage/index';
import SignupPage from './pages/SignupPage/index';
import PrivacyPolicy from './pages/PrivacyPolicy/index';
import UserProfile from './pages/UserProfile/index';
import UserHome from './pages/UserHome/index';
import UpdateProfile from './pages/UpdateProfile/index';
import Forgetpassword from './pages/ForgetPassword/index';
import VerfiyCode from './pages/VerfiyCode/index';
import ChangePassword from './pages/ChangePassword/index';
import Bootstrap from './component/Bootstrap/Bootstrap';
import UserCart from './pages/userPages/UserCart/index';
import UserBlog from './pages/userPages/UserBlog/index';
import IndexUserInstructor from './pages/userPages/UserShowInstructor/index';
import Testform from './pages/Testform/TestForm';
import SavedCourses from './pages/SavedCourses/index';
import InstructorHome from './pages/InstructorAsUser/InstrectorHome/index';
import InstructorCreateCourse from './pages/InstructorAsUser/InstructorDashboard/index';
import CreateArticle from './pages/InstructorAsUser/InstructorDashboard/InstructorcreateArticle/index';
import ArticleDetails from './component/ArticleDetailsById/Article_Details';
import UpdateArticle from './pages/InstructorAsUser/InstructorDashboard/instructorUpdate/index'
import EditInformation from './pages/InstructorAsUser/InstrectorHome/EditInformation/index';
import UpdateCourse from './pages/InstructorAsUser/InstructorDashboard/instructorUpdateCourse/index';
import UserSavedCourse from './pages/userPages/UserSavedCourse/index';
import Error404 from './component/Error404/Error404';
import ForgetInstructorPassword from './pages/InstructorAsUser/InstrectorHome/ForgetInstructorPassword';
import VerificationCode from './pages/InstructorAsUser/InstrectorHome/OtpInstructor';
import ChangeOtpPassword from './pages/InstructorAsUser/InstrectorHome/ChangeOtpPassword';
import VerificationEmail from './pages/InstructorAsUser/InstrectorHome/OtpInstructorEmailVerfiy';
import RegistrationSuccess from './pages/SignupPage/RegistrationSuccess';
import EnterpriseVerificationEmail from './pages/SignupPage/EnterpriseCodeVerfication';
import EnterpriseInformationForm from './pages/Enterprise/EnterpriseInformationForm';
import EnterpriseLayout from './pages/Enterprise/EnterpriseLayout';
import EnterpriseHome from './pages/Enterprise/EnterpriseHome';
import EnterpriseCreateCourse from './pages/Enterprise/createcourse/EnterpriseCreateCourse';
import UpdateEnterpriseCourse from './pages/Enterprise/UpdateEnterpriseCourse';
import ForgetEnterprise from './pages/Enterprise/Auth/forgetEnterprise';
import OtpEnterprise from './pages/Enterprise/Auth/OtpEnterprise';
import ChangeOtpPasswordEnterprise from './pages/Enterprise/Auth/changepasswordEnterprise';
import UserVerfiyemail from './pages/userPages/UserVerfiyemail';
import EnterpriseCourseDetails from './pages/CourseDetails/EnterpriseCourseDetails';

function MainApp() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Show loader when route is changing
    setIsLoading(true);

    // Hide loader after a short delay to simulate content loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
       
      {isLoading && <Loading />} {/* Show loading when isLoading is true */}
      <Routes>
         
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<UserHome />} />
        <Route path="/Blogs" element={<Blog />} />
        <Route path="/instructor/:id" element={<IndexUserInstructor  />} /> {/* New route for instructor details */}
       {/* <Route path="/instructor/:id" element={<IndexUserInstructor  />} /> {/* New route for instructor details */}
        <Route path="/Cart" element={<CartPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/coursedetails/:id" element={<CourseDetails />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/Signup" element={<SignupPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/profile/:slug" element={<UserProfile />} />
        <Route path="/update-profile" element={<UpdateProfile />} />
        <Route path="/forget-password" element={<Forgetpassword />} />
        <Route path="/verfiy-code" element={<VerfiyCode />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/Bootstrap" element={<Bootstrap />} />
        <Route path="/UserCart" element={<UserCart />} />
        <Route path="/verfiy-email" element={<UserVerfiyemail />} />
        <Route path="/UserBlog" element={<UserBlog />} />
        <Route path="/User-saved-course" element={<UserSavedCourse />} />
        <Route path="/Testform" element={<Testform />} />
        <Route path="/savedcourse" element={<SavedCourses />} />
        <Route path="/article/:id" element={<ArticleDetails />} /> {/* Route to article details page */}
        <Route path="/Instructor-Home" element={<InstructorHome />} />
        <Route path="/createcourse" element={<InstructorCreateCourse />} />
        <Route path="/create-Article" element={<CreateArticle />} />
        <Route path="/update-article/:id" element={<UpdateArticle />} />
        <Route path="/update-course/:id" element={<UpdateCourse />} />
        <Route path="/Edit-information" element={<EditInformation />} />
        <Route path="/instructor-forgot-password" element={<ForgetInstructorPassword />} />
        <Route path='/instructor-VerficationCode' element={<VerificationCode/>}/>
        <Route path='/instructor-VerficationEmail' element={<VerificationEmail/>}/>
        <Route path='/instructor-changepassword-otp' element={<ChangeOtpPassword />} />
        <Route path='/RegistrationSuccess' element={<RegistrationSuccess />} />
        
         {/* Enterprise Routes */}
         <Route path="/enterprise/coursedetails/:id" element={<EnterpriseCourseDetails />} />
         <Route path='/forgot-password-enterprise' element={<ForgetEnterprise />} />
         <Route path='/otp-forget-enterprise' element={<OtpEnterprise />} />
         <Route path='/otp-changepassword-enterprise' element={<ChangeOtpPasswordEnterprise />} />

         <Route path='/enterprise' element={<EnterpriseLayout />}>
               <Route path='Enterprise-Verfication-Email' element={<EnterpriseVerificationEmail />} />
               <Route path='Information-form' element={<EnterpriseInformationForm />} />
               <Route path='home' element={<EnterpriseHome />} />
               <Route path='create-course' element={<EnterpriseCreateCourse />} />
               <Route path="update-course/:id" element={<UpdateEnterpriseCourse />} />
         </Route>
     
     {/* Catch-all route for 404 */}
         <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}
export default MainApp;

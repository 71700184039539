import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config'; // Import the base URL from your config

// Async thunk to fetch enterprise courses
export const fetchEnterpriseCourses = createAsyncThunk(
  'enterpriseCourses/fetchEnterpriseCourses',
  async (isArchived , { rejectWithValue }) => {
    try {
      const token = Cookies.get('entoken'); // Fetch the token from cookies
      const response = await axios.post(`${URL}/enterprise/courses`,{ is_archived : isArchived}, {
        headers: {
           token, // Add token to the header
        },
           
      });
      return response.data; // Successful response
    } catch (error) {
      // Handle error and pass it to rejectWithValue
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

const enterpriseCoursesSlice = createSlice({
  name: 'enterpriseCourses',
  initialState: {
    courses: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnterpriseCourses.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchEnterpriseCourses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.courses = action.payload.data.courses; // Store the courses
      })
      .addCase(fetchEnterpriseCourses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default enterpriseCoursesSlice.reducer;

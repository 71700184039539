
import React , { useState , useEffect } from 'react'
import Footer from '../../../component/Footer/Footer';
import FooterAR from '../../../component/FooterArabic/FooterAr';
import { useLanguage } from '../../../context/LanguageContext'
//import UserCart from './UserCart';
import MobileNavbar from '../../../component/mobileNavbar/MobileNavbar';
import Sidebar from '../../../component/Sidebar'
import useAuth from '../../../CustomHooks/useAuth';
import UserNavbar from '../../../component/UserNavbar/UserNavbar';
import UserSavedCourse from './UserSavedCourse';
const IndexUserSaved = () => {
       useAuth()
     // this from context api to manage language 
     const { language, toggleLanguage } = useLanguage();
     console.log('search page',language)
     //const isLogggedin = true
// const [language , toggleLanguage ]= useLanguage() ; .
// IsMobile and Responsive
const [isMobile, setIsMobile] = useState(false);
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200);
  };
  // Initial check
  handleResize();
  // Event listener for window resize
  window.addEventListener('resize', handleResize);
  // Clean-up
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div>
       <>
       {!isMobile && (<>
        {language === 'en' && isMobile === false ? 
           <div style={{display:'flex',justifyContent:'space-between'}}><Sidebar /> <UserNavbar/></div>
          : 
        <div> navbar </div>
     } 
       </>)
       }
       </>
       <>
   {isMobile && (
       <MobileNavbar /> 
   )}
     <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      {language === 'en' ? <div style={{width:'80%',marginLeft:'260px'}}><UserSavedCourse /></div>   : <h1>hakim</h1>} 
     
      </div>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'80%',marginLeft:'260px'}}>
     
      </div>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'80%',marginLeft:'260px'}}>
      {language === 'en' ?  <Footer/> : <FooterAR />} 
      </div>
      </>
    </div>

  )
}
export default IndexUserSaved

import React, { useState, useEffect } from 'react';
import IconLocation from '../../assets/images/LocationMap.svg';
import { motion, AnimatePresence } from 'framer-motion';
import './courseLocation.css'


const CourseLocation = ({ course }) => {
  const images = course?.images || []; // Array of images
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle image switch (next image)
  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to handle image switch (previous image)
  const goToPreviousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Auto-slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      goToNextImage();
    }, 5000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div>
      {/* Course Location Container */}
      <div className="CourseLocation_Container">
        <div className="CourseLocation_Sec1">Course Location</div>

        {/* Section 2 Address */}
        <div className="CourseLocation_Sec2">{course?.address}</div>

        {/* Section 2 Slider */}
        <div
          className="CourseLocation_Sec2"
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '90%',
            height: '400px', // Adjust height based on your design
          }}
        >
          <AnimatePresence>
            <motion.img
              key={currentIndex}
              src={images[currentIndex]?.image}
              alt={`Course image ${currentIndex + 1}`}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
          </AnimatePresence>

          {/* Left Arrow */}
          <div
            onClick={goToPreviousImage}
            style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              background: '#fff',
              borderRadius: '50%',
              padding: '10px',
              cursor: 'pointer',
              zIndex: 10,
            }}
          >
            &#8592;
          </div>

          {/* Right Arrow */}
          <div
            onClick={goToNextImage}
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              background: '#fff',
              borderRadius: '50%',
              padding: '10px',
              cursor: 'pointer',
              zIndex: 10,
            }}
          >
            &#8594;
          </div>

          {/* Indicator Balls */}
          <div
            style={{
              position: 'absolute',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: '10px',
              zIndex: 10,
            }}
          >
            {images.map((_, index) => (
              <motion.div
                key={index}
                onClick={() => setCurrentIndex(index)}
                initial={{ scale: 1 }}
                animate={{
                  scale: index === currentIndex ? 1.4 : 1,
                  backgroundColor: index === currentIndex ? '#007bff' : '#ccc',
                }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                }}
              />
            ))}
          </div>
        </div>

        {/* Section 3 Map */}
        <div className="CourseLocation_Sec3_map">
          {/* Start Section 4 */}
          <div className="CourseLocation_Sec4">
            <img src={IconLocation} alt="IconLocation" />
            <div>View on google map</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLocation;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import Spinner from 'react-bootstrap/Spinner';
import PasswordInput from '../../component/PasswordInput/PasswordInput';
import { useDispatch, useSelector } from 'react-redux';
import { loginEnterprise } from '../../store/enterpriseFeature/loginEnterpriseSlice';



const EnterpriseLogin = () => {

  const navigate = useNavigate(); // استخدام useNavigate
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.loginenterprise); // الحصول على حالة الـ loading و الـ error من الـ store



  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLoginSubmit = () => {
    if (!email || !password) {
      return; // إذا لم يكن هناك بريد إلكتروني أو كلمة مرور، لا نتابع
    }

    dispatch(loginEnterprise({ email, password }))
      .unwrap() // استخدام unwrap للحصول على النتيجة مباشرة
      .then(() => {
        navigate('/enterprise/home'); // التوجيه إلى /enterprise عند النجاح
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });
  };
  const handleKeyPress = (e) => {
    console.log('Key Pressed:', e.key); // طباعة المفتاح المضغوط
    if (e.key === 'Enter') {
      e.preventDefault();
      console.log('Enter pressed, submitting form');
      handleLoginSubmit();
    }
  };
  

  return (
    <form className='Login_body_input_container' onSubmit={handleLoginSubmit}>
      <div className='Signup_body_Form_Sec4'>
        <div>Email</div>
        <div className='Signup_body_input'>
          <input
            className='Sign_input'
            type="text"
            placeholder='example@example.com'
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyPress}
          />
        </div>
      </div>

      <div className='Signup_body_Form_Sec4'>
        <div>Password</div>
        <div className='Signup_body_input'>
          <PasswordInput
            className='Sign_input'
            type="password"
            placeholder='Password'
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyPress}
          />
        </div>
      </div>

      <div className='Error_message'>
        {error && (
          <div style={{ color: 'red', marginTop: '10px'}}>
            <p>{error.msg}</p>
          </div>
        )}
      </div>

      <div className='Signup_body_Form_Sec5' style={{display:'flex',flexDirection:'row-reverse'}}>
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <button className='Signup_body_Form_Sec5_button' onClick={handleLoginSubmit}>
            Login
          </button>
        )}

        <div style={{ display: 'flex', flexDirection: "column-reverse", alignItems: 'flex-start', gap: '10px' }}>
          <Link to='/forgot-password-enterprise'>
            <div style={{ cursor: 'pointer' }}>Forget Password ?</div>
          </Link>
          <div>Don’t have an account ? <Link to='/Signup' className='link'><span>Sign Up</span></Link></div>
        </div>
      </div>
    </form>
  );
};

export default EnterpriseLogin;

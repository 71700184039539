import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Instructor.css';
import InstructorIcon from '../../assets/images/instructors/instructor1.png'; // Default placeholder
import StarIcon from '../../assets/images/Course/Star.png';
import { userFetchAllInstructors } from '../../store/userFeature/userFetchAllInstructors';
import { followInstructor, unfollowInstructor } from '../../store/userFeature/userFollowInstructorSlice';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';


const GetInstructor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  // Redux state
  const reduxInstructors = useSelector((state) => state.userGetAllInstructors.instructors || []);
  const fetchStatus = useSelector((state) => state.userGetAllInstructors.status);
  const error = useSelector((state) => state.userGetAllInstructors.error);

  const [loadingIds, setLoadingIds] = useState([]); // Tracks loading for specific instructors
  const [instructors, setInstructors] = useState([]); // Local state for instructors

  // Synchronize local state with Redux state on first load
  useEffect(() => {
    if (reduxInstructors.length > 0) {
      setInstructors(reduxInstructors);
    }
  }, [reduxInstructors]);

  useEffect(() => {
    dispatch(userFetchAllInstructors());
  }, [dispatch]);

  const handleFollow = async (e, instructor) => {
    e.stopPropagation();
    const authToken = Cookies.get('authToken');

    if (!authToken) {
      setShowPopup(true);
      return;
    }

    // Add the instructor ID to the loading list
    setLoadingIds((prev) => [...prev, instructor.id]);

    try {
      if (instructor.is_follow) {
        // Unfollow instructor
        await dispatch(unfollowInstructor(instructor.id));
        setInstructors((prev) =>
          prev.map((inst) =>
            inst.id === instructor.id ? { ...inst, is_follow: false } : inst
          )
        );
      } else {
        // Follow instructor
        await dispatch(followInstructor(instructor.id));
        setInstructors((prev) =>
          prev.map((inst) =>
            inst.id === instructor.id ? { ...inst, is_follow: true } : inst
          )
        );
      }
    } finally {
      // Remove the instructor ID from the loading list
      setLoadingIds((prev) => prev.filter((id) => id !== instructor.id));
    }
  };

  const closePopup = () => setShowPopup(false);

  const handleCardClick = (instructor) => {
    const authToken = Cookies.get('authToken');
    if (authToken) {
      navigate(`/instructor/${instructor.id}`, { state: { instructor } });
    } else {
      setShowPopup(true);
    }
  };

  return (
    <div className="Instructor_List_Container" style={{ cursor: 'pointer' }}>
      {fetchStatus === 'loading' && <p>Loading instructors...</p>}
      {fetchStatus === 'failed' && <p>Error: {error}</p>}

      {instructors && instructors.length > 0 ? (
        instructors.map((instructor) => (
          <div
            className="Instructor_Container"
            key={instructor.id}
            onClick={() => handleCardClick(instructor)}
          >
            <div className="Instructor_Sec1">
              <img
                src={instructor.profile_image || InstructorIcon}
                className="Instructor_Profile_Picture"
                alt={`${instructor.name || 'Instructor'}'s icon`}
              />
            </div>

            <div className="Instructor_Sec2">{instructor.name || 'No Name Provided'}</div>

            <div className="Instructor_Sec3">{instructor.career || 'Lndex Instructor'}</div>

            <div className="Instructor_Sec4">
              <img src={StarIcon} alt="Star" />
              <div>
                {instructor.rating ? `${instructor.rating}%` : 'N/A'} ({instructor.reviews || 0} reviews)
              </div>
            </div>

            <div className="Instructor_Sec5">
              {instructor.is_follow ? (
                <button
                  onClick={(e) => handleFollow(e, instructor)}
                  disabled={loadingIds.includes(instructor.id)}
                  style={{backgroundColor:'#18172F', color:'#fff'}}
                >
                  {loadingIds.includes(instructor.id) ? 'Unfollowing...' : 'Unfollow'}
                </button>
              ) : (
                <button
                  onClick={(e) => handleFollow(e, instructor)}
                  disabled={loadingIds.includes(instructor.id)}
                >
                  {loadingIds.includes(instructor.id) ? 'Following...' : 'Follow'}
                </button>
              )}
            </div>
          </div>
        ))
      ) : (
        fetchStatus === 'failed' && <p>No instructors found.</p>
      )}

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div>
              <span className="close" onClick={closePopup}>
                &times;
              </span>
            </div>
            <div>
              <p className="Popup_Message">
                This feature is available only to registered users. Please sign in or create an account to unlock full access.
              </p>
            </div>
            <div className="popup_button_container">
              <Link className="link" to="/Login">
                <div>
                  <button className="popup_button_Login">Login</button>
                </div>
              </Link>
              <Link className="link" to="/Signup">
                <div>
                  <button className="popup_button_Signup">Sign Up</button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetInstructor;

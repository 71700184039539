// استيراد الدوال اللازمة من Redux Toolkit
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// استيراد مكتبة axios لإجراء الطلبات
import axios from 'axios';
// استيراد URL من ملف الإعدادات
import { URL } from '../../config';
// استيراد مكتبة Cookies للتعامل مع ملفات الكوكيز
import Cookies from 'js-cookie';

// دالة createAsyncThunk تُستخدم لإنشاء thunk لتحديث ملف تعريف المدرب
export const updateInstructorProfile = createAsyncThunk(
  // تسمية الـ action وتحديد اسمها لتسهيل التعامل معها
  'instructor/updateInstructorProfile',
  // الدالة التي تقوم بتنفيذ الطلب غير المتزامن (async request)
  async (formData, { rejectWithValue }) => {
    // جلب التوكن (token) من ملفات الكوكيز للتحقق من هوية المستخدم
    const token = Cookies.get('Instructor_Token');
    try {
      // إرسال طلب POST إلى endpoint لتحديث ملف المدرب
      const response = await axios.post(`${URL}/instructor/update-profile`, formData, {
        headers: {
          // إدراج التوكن (token) في الرؤوس (headers) للتحقق من الهوية
          token: token,
        },
      });
      // إذا نجح الطلب، نقوم بإرجاع البيانات
      return response.data;
    } catch (error) {
      // في حالة الخطأ، نقوم بإرجاع الخطأ عبر rejectWithValue
      return rejectWithValue(error.response?.data?.msg || 'Something went wrong');
    }
  }
);

// إنشاء slice لإدارة حالة تحديث ملف المدرب
const updateInstructorProfileSlice = createSlice({
  name: 'instructor', // اسم الشريحة
  initialState: {
    profile: null,
    status: 'idle',
    updateStatus: 'idle', // يجب أن تكون موجودة هنا
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateInstructorProfile.pending, (state) => {
        console.log('Updating profile...'); // هذا سيساعدك على معرفة إذا كانت الدالة تعمل
        state.updateStatus = 'loading';
      })
      .addCase(updateInstructorProfile.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        state.profile = action.payload;
      })
      .addCase(updateInstructorProfile.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.error = action.payload;
      });
  },
});
  
  

// تصدير الـ reducer لاستخدامه في المتجر (store)
export default updateInstructorProfileSlice.reducer;

// src/store/userFeature/userDeleteCourseSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk for deleting a saved course
export const deleteSavedCourse = createAsyncThunk(
  'user/deleteSavedCourse',
  async ({ courseId, isEnterprise }, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken'); // Get the token from cookies
   
      // Make the API call to delete the course
      const response = await axios.post(`${URL}/user-courses-delete`, 
        {  
          course_id: courseId,
          is_enterprise: isEnterprise , 
        
        }, // Send the course_id in the request body
        {
            headers: {
              token: token,
            },
          }
      );
      return response.data; // Return the response from the server
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error deleting course');
    }
  }
);

// Slice for handling delete state
const userDeleteCourseSlice = createSlice({
  name: 'userDeleteCourse',
  initialState: {
    deleting: false, // State for deleting a course
    deleteSuccess: false, // Whether the course was successfully deleted
    error: null, // Any error encountered during deletion
  },
  reducers: {
    resetDeleteState(state) {
      // Reset delete-specific states
      state.deleting = false;
      state.deleteSuccess = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteSavedCourse.pending, (state) => {
        state.deleting = true;
        state.deleteSuccess = false;
        state.error = null;
      })
      .addCase(deleteSavedCourse.fulfilled, (state, action) => {
        state.deleting = false;
        state.deleteSuccess = true;
        state.error = null;
      })
      .addCase(deleteSavedCourse.rejected, (state, action) => {
        state.deleting = false;
        state.deleteSuccess = false;
        state.error = action.payload || 'Error deleting course';
      });
  },
});

// Export actions
export const { resetDeleteState } = userDeleteCourseSlice.actions;

// Export reducer
export default userDeleteCourseSlice.reducer;

// src/features/allCourses/allCoursesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import Cookies
import { URL } from '../../config';


// Async thunk to fetch all courses
export const fetchAllCourses = createAsyncThunk(
  'user/fetchAllCourses',
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken'); // Get the token from cookies
      if (!token) {
        throw new Error('User is not authenticated'); // Handle case where token is missing
      }

      const response = await axios.get(`${URL}/all-courses`, {
        headers: {
        token,
        },
      });

      return response.data.data.courses; // Return the courses array
    } catch (error) {
      // Return a custom error message
      return rejectWithValue(
        error.response?.data?.msg || error.message || 'Something went wrong'
      );
    }
  }
);

// Slice
const alluserCoursesSlice = createSlice({
  name: 'allCourses',
  initialState: {
    courses: [],
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
  },
  reducers: {
    // You can add synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCourses.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAllCourses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.courses = action.payload;
      })
      .addCase(fetchAllCourses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default alluserCoursesSlice.reducer;

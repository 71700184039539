// import الأدوات المطلوبة
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { URL } from "../../config"; // تعديل المسار حسب الحاجة

// تعريف الـ asyncThunk لتسجيل الكورس
export const registerEnterpriseCourse = createAsyncThunk(
  "enterprise/registerCourse",
  async (formData, { rejectWithValue }) => {
    try {
      const token = Cookies.get("entoken"); // الحصول على التوكن
      const response = await axios.post(
        `${URL}/enterprise/courses/store`,
        formData,
        {
          headers: {
            token,
            'Content-Type': 'multipart/form-data', // تأكد من إرسالها كـ FormData
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Something went wrong!"
      );
    }
  }
);

// تعريف السلايس
const enterpriseCourseSlice = createSlice({
  name: "enterpriseCourse",
  initialState: {
    isLoading: false,
    success: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerEnterpriseCourse.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerEnterpriseCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = action.payload;
      })
      .addCase(registerEnterpriseCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// تصدير الريدويسر
export default enterpriseCourseSlice.reducer;

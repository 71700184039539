import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';

// Async thunk for forget-password
export const instructorForgetPassword = createAsyncThunk(
  'instructor/forgetPassword',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/instructor/forget-password`, { email });
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// Slice for forget-password
const instructorForgetPasswordSlice = createSlice({
  name: 'instructorForgetPassword',
  initialState: {
    status: null,   // To handle API status
    message: '',    // To store API message
    loading: false, // To indicate loading state
    error: null,    // To handle API errors
  },
  extraReducers: (builder) => {
    builder
      .addCase(instructorForgetPassword.pending, (state) => {
        state.loading = true;
        state.status = null;
        state.message = '';
        state.error = null;
      })
      .addCase(instructorForgetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.status = true;
        state.message = action.payload.msg; // Success message from API
        state.error = null;
      })
      .addCase(instructorForgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.status = false;
        state.message = '';
        state.error = action.payload || 'Something went wrong'; // Error message
      });
  },
});

export default instructorForgetPasswordSlice.reducer;

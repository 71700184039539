// file: src/store/enterpriseFeature/updateEnterpriseProfileSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL } from "../../config";
import Cookies from 'js-cookie';

// Async thunk لتحديث البيانات
export const updateEnterpriseProfile = createAsyncThunk(
  "enterpriseProfile/update",
  async (formData, { rejectWithValue }) => {
    try {
      const token = Cookies.get('entoken');
      if (!token) throw new Error("Token is missing");
      const response = await axios.post(`${URL}/enterprise/update-profile`, formData, {
        headers: {
          token :  token,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

const updateEnterpriseProfileSlice = createSlice({
  name: "updateEnterpriseProfile",
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateEnterpriseProfile.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateEnterpriseProfile.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(updateEnterpriseProfile.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export default updateEnterpriseProfileSlice.reducer;

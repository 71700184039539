import React , {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import CourseImg from '../../assets/images/Course/Courseimage.png';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import './courseDashboard.css';
import DeleteCourse from '../../assets/images/DeleteCourse.svg';
import UpdateCourseIcon from '../../assets/images/editcourse.svg';
import { archiveCourse } from '../../store/features/CourseArchiveSlice';
import { deleteCourse } from '../../store/features/CourseDeleteSlice'; // Import deleteCourse thunk


const CourseDashboard = ({ course }) => {
  // استخدام هوك useNavigate للتنقل بين الصفحات
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
 // State for handling success/error messages
 const {  message, error  } = useSelector((state) => state.courseArchive);
 const [loading, setLoading] = useState(false);
 const [deleteLoading, setDeleteLoading] = useState(false); // Loading for delete button
    // Function to handle the archive button click
    //
 // حالة loading الخاصة بالدورة الفردية

  
 // دالة لتحديث حالة الدورة عند الضغط على زر الأرشفة
 const handleArchiveClick = async () => {
   // تعيين loading إلى true عند الضغط على زر الأرشفة
   setLoading(true);
   
   try {
     // إرسال الـ course.id إلى الـ dispatch لتنفيذ الأرشفة
     await dispatch(archiveCourse(course.id));

     // Reload the page after successful archiving
    window.location.reload(); // Reload the page
   } catch (error) {
     console.error("Error archiving course:", error);
   } finally {
     // تعيين loading إلى false بعد الانتهاء من العملية
     setLoading(false);
   }
 };

  // Function to handle deleting a course
  const handleDeleteClick = async () => {
    setDeleteLoading(true); // Set delete loading state
    try {
      await dispatch(deleteCourse(course.id)); // Dispatch deleteCourse thunk
      window.location.reload(); // Reload page on success
    } catch (error) {
      console.error("Error deleting course:", error);
    } finally {
      setDeleteLoading(false); // Reset loading state
    }
  };






  // دالة لمعالجة النقر على زر التحديث والتنقل إلى صفحة التحديث مع تمرير بيانات الدورة
  const handleUpdateClick = () => {
    navigate(`/update-course/${course.id}`, { state: { course } });
  };

  // Utility function to truncate text
const truncate = (text, limit) => {
  if (text.length > limit) {
    return text.slice(0, limit) + '...';
  }
  return text;
};

 
  return (
    <>
    <div className='Course_Container'>
      {/* عرض صورة الدورة */}
      <div className='Course_Sec1'>
        <img style={{ width: '348px', height: '220px' , borderRadius:'8px 8px 0px 0px' }} src={course.image || CourseImg} alt='Course' />
      </div>
      {/* عرض تاريخ بدء الدورة */}
      <div className='Course_Sec2'>
        Start {course.start_date || 'N/A'}
      </div>
      {/* عرض عنوان الدورة */}
      <div className='Course_Sec3_at_dashboard'>
        {course.title}
      </div>
      {/* عرض عدد الساعات وعدد الجلسات */}
      <div className='Course_Sec4'>
        <img src={Clock} alt='Clock' /> {course.course_hours} hours &nbsp; ({course.session_count} sessions)
      </div>
      {/* عرض عدد المقاعد المتبقية وتقييم الدورة */}
      <div className='Course_Sec5'>
        <img src={Chair} alt='Chair' /> {course.seats_count} seats left
        <img src={Line} alt='Line' />
        <img src={Start} alt='Start' /> {course.rate || 'N/A'} ({course.session_count || 0})
      </div>
      {/* عرض وصف الدورة */}
   
     <div className="Course_Sec6">
       {truncate(course.description, 50)}
     </div>
      {/* عرض أزرار الأرشفة وتعديل وحذف الدورة */}
         {/* Archive button with loading state */}
         <div className="Course_Sec7">
         <button
            className="Course_Dashboard_Archive"
            onClick={handleArchiveClick}
            disabled={loading}  // تعطيل الزر أثناء التحميل
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Spinner لعرض عند التحميل
            ) : (
              'Archive'
            )}
          </button>
        <div className='course_icon_modify'>
          <img src={UpdateCourseIcon} alt='Update Course' onClick={handleUpdateClick} />
                      {/* Delete icon with click handler */}
                      <img
              src={DeleteCourse}
              alt='Delete Course'
              onClick={handleDeleteClick} // Added delete handler
              style={{ cursor: 'pointer' }}
            />
            {/* Loading spinner for delete button */}
            {deleteLoading && (
              <div className="spinner-border spinner-border-sm" role="status"></div>
            )}

        </div>
       
      </div>
    
    </div>
   
    </>
  );
};

export default CourseDashboard;

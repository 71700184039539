import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import {URL} from '../../config';

// AsyncThunk to update profile image
export const updateEnterpriseImage = createAsyncThunk(
  'enterprise/updateProfileImage',
  async (formData, { rejectWithValue }) => {
    try {
      // Get the token from cookies
      const token = Cookies.get('entoken');

      // Make the POST request to update the profile image
      const response = await axios.post(
        `${URL}/enterprise/update-profile-image`,
        formData,
        {
          headers: {
            token: token,
          },
        }
      );

      // Return the response data
      return response.data;
    } catch (error) {
      // Handle errors and reject with error data
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

// Slice to handle update profile image state
const updateProfileImageSlice = createSlice({
  name: 'updateProfileImage',
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateEnterpriseImage.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateEnterpriseImage.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(updateEnterpriseImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default updateProfileImageSlice.reducer;

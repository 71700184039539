import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorProfile } from '../../../../store/features/instructorGetProfileSlice';
import { updateInstructorProfile } from '../../../../store/features/InstructorUpdateProfileSlice';
import RichTextEditor from '../../../../component/RichTextEditor/RichTextEditor';
import './EditIstructor.css';
import { useNavigate } from 'react-router-dom';
import CityDropdown from '../../InstructorDashboard/City';


const EditInformation = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const instructorProfile = useSelector(state => state.instructorProfile.profile);
  const loading = useSelector(state => state.updateInstructorProfile.updateStatus === 'loading');

  const error = useSelector(state => state.instructorProfile.error);
  const updateStatus = useSelector(state => state.instructorProfile.updateStatus);


  const [submitError, setSubmitError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    about_details: '',
    job_title: '',
    career: '',
    linked_in: '', 
    address: '' ,
    city_id: ''
  });

  useEffect(() => {
    dispatch(fetchInstructorProfile());
  }, [dispatch]);

  useEffect(() => {
    console.log('Instructor Profile:', instructorProfile); // طباعة لتأكيد هيكل الكائن
    if (instructorProfile && instructorProfile.instructor) {
      const { instructor } = instructorProfile;
      console.log('instructor access : ' , instructor )
      setFormData({
        first_name: instructor.first_name || '',
        last_name: instructor.last_name || '',
        email: instructor.email || '',
        phone: instructor.phone || '',
        about_details: instructor.about_details || '',
        job_title: instructor.job_title || '',
        career: instructor.career || '',
        linked_in: instructor.linked_in || '', 
        address: instructor.address || '' ,
        city_id: instructor.city_id.id || '' // تحديث city_id
      });
    }
  }, [instructorProfile]);

  const handleQuillChange = (value) => {
    setFormData((prevState) => ({
      ...prevState, // Keep all other fields
      about_details: value, // Update only the about_details field
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target; // الحصول على الاسم والقيمة من الإدخال
    setFormData({ ...formData, [name]: value }); // تحديث الحقل المحدد في formData
  };


  const handleCityChange = (cityId) => {
    setFormData({ ...formData, city_id: cityId });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);
    try {
      const response = await dispatch(updateInstructorProfile(formData)).unwrap();
      console.log('Profile update response:', response);
       // عرض التحميل لمدة 3 ثواني
    setShowSuccessMessage(true); // عرض رسالة النجاح مؤقتًا
    setTimeout(() => {
      Navigate('/Instructor-Home'); // الانتقال إلى الصفحة الجديدة
    }, 800); // 3000 مللي ثانية = 3 ثواني
    } catch (error) {
      setSubmitError(error);
    }
  };

  return (
    <div className='Edit_Instructor_Container'>
      <h2>Edit Profile Information</h2>

      {loading && <p>Loading profile...</p>}
      {error && <p>Failed to load profile: {error}</p>}

      {instructorProfile && (
        <form className="edit-profile-form" onSubmit={handleSubmit}>
          <label>First Name:</label>
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />
          
          <label>Last Name:</label>
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />

          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />

          <label>Phone:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
            maxLength={15} // لتقييد الحد الأقصى لعدد الأحرف
            pattern="\d*" // لتقبل الأرقام فقط
            title="Please enter a valid phone number containing only numbers." // نص يظهر عند عدم المطابقة للنمط
          />

          <label>About:</label>
          <RichTextEditor
            value={formData.about_details}
            onChange={handleQuillChange}
          />
          <label>Job Title:</label>
          <input
            type="text"
            name="job_title"
            value={formData.job_title}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />

          <label>Career:</label>
          <input
            type="text"
            name="career"
            value={formData.career}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />

        <label>LinkedIn:</label>
          <input
            type="text"
            name="linked_in"
            value={formData.linked_in}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />
<div className="address-city-container" style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' , flexDirection:'row-reverse' }}>
  <div className="address-input">
    <label>Address:</label>
    <input
      type="text"
      style={{width:'450px'}}
      name="address"
      value={formData.address}
      onChange={handleChange}
      className="InstructorEdit_form_Section_input"
    />
  </div>

  <div className="city-dropdown">
    <label>City:</label>
    <CityDropdown 
      selectedCity={formData.city_id}
      onCityChange={handleCityChange} 
    />
  </div>
</div>

          <div className='InstructorEdit_form_submit_button_container'> 
          <button 
  type="submit" 
  className="InstructorEdit_form_submit_button d-flex align-items-center justify-content-center" 
  disabled={updateStatus === 'loading'}
>
  {updateStatus === 'loading' ? (
    <>
      <div className="spinner-border spinner-border-sm me-2" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      Updating...
    </>
  ) : (
    'Update Profile'
  )}
</button>

{updateStatus === 'loading' && (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading... Please wait.</p>
          </div>
        )}


          </div>

          <div className="message-container">
            {showSuccessMessage && <p style={{color:'green', fontWeight:'700', fontSize:'15px'}} className="SuccessMessage">Profile updated successfully!</p>}
            {submitError && <p className="ErrorMessage">Failed to update profile: {submitError}</p>}
          </div>
        </form>
      )}
    </div>
  );
};

export default EditInformation;

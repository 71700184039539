// updateEnterpriseCourseSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { URL } from "../../config"; // Import the base URL

// Async thunk for updating an enterprise course
export const updateEnterpriseCourse = createAsyncThunk(
  "enterprise/updateCourse",
  async ({ courseId, updateData }, { rejectWithValue }) => {
    try {
      // Retrieve token from cookies
      const token = Cookies.get("entoken");

      // Make the API request using axios
      const response = await axios.post(
        `${URL}/enterprise/courses/update/${courseId}`,
        updateData,
        {
          headers: {
            token, // Attach the token
          },
        }
      );

      // Return the API response data
      return response.data;
    } catch (error) {
      // Handle errors
      const errorMessage =
        error.response?.data?.message || "Failed to update course";
      return rejectWithValue(errorMessage);
    }
  }
);

// Slice definition
const updateEnterpriseCourseSlice = createSlice({
  name: "updateEnterpriseCourse",
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    resetUpdateState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateEnterpriseCourse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateEnterpriseCourse.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(updateEnterpriseCourse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

// Export the actions and reducer
export const { resetUpdateState } = updateEnterpriseCourseSlice.actions;
export default updateEnterpriseCourseSlice.reducer;

import React, { useState } from 'react'
//import './InstructorNav.css'
import LndexLogo from '../../assets/images/lndexlogo_svg.svg' 
import NotificationIcon from '../../assets/images/Notfication.svg'
import WorldLanguage from '../../assets/images/worldLanguage.svg'
import { useLanguage } from '../../context/LanguageContext'
import { motion } from "framer-motion";



// Redux-Toolkit  =>>>>> 
import { useDispatch , useSelector } from 'react-redux'
import { logoutEnterprise } from '../../store/enterpriseFeature/logoutEnterpriseSlice'
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';


const EnterPriseNav = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { language, toggleLanguage } = useLanguage(); // Access language context


  const handleLogout = async () => {
    setLoading(true); // بدء التحميل
    setError(null); // إعادة تعيين الخطأ

    try {
        await dispatch(logoutEnterprise()).unwrap(); // إرسال طلب الخروج
        Cookies.remove('entoken'); // إزالة الـ entoken من الكوكيز
        navigate('/login'); // التنقل إلى صفحة تسجيل الدخول
    } catch (err) {
        setError(err.message || 'Logout failed'); // التعامل مع الخطأ
    } finally {
        setLoading(false); // إيقاف التحميل
    }
};

  return (
    <div className='InstructorNav_Container'>
          {/* The LeftSide Logo  */}
         <div className='InstructorNav_Logo'>
            <Link to='/enterprise/home' className='link'> <img src={LndexLogo} alt='logo' /></Link>
         </div>
          {/* The Right Side  */}
          <div className='InstructorNav_RightSide'>
                 <div> <img src={NotificationIcon} alt='notficationIcon'/></div>
                 <div>
                 <motion.button
    className='InstructorNav_LanguageButton'
    onClick={toggleLanguage}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.2 }}
  >
    <img src={WorldLanguage} alt='langIcon' />
    {language === 'ar' ? 'English' : 'العربية'}
  </motion.button>
                  
                  </div>
                 <div><button
                        className='InstructorNav_LogoutButton'
                        onClick={handleLogout}
                        disabled={loading} // تعطيل الزر أثناء التحميل
                    >
                        {loading ? (
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="sr-only" style={{ color: '#000' }}></span>
                            </div>
                        ) : (
                            `${language === 'en' ? 'Logout' : 'تسجيل الخروج'}`
                        )}
                    </button>
               </div>
          </div>
          {error && <p style={{ color: 'red' }}>Error: {error}</p>}
    </div>
  )
}
export default EnterPriseNav
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config'; // استيراد الـ URL من ملف الـ config

// الـ thunk المسؤول عن تسجيل الخروج
export const logoutEnterprise = createAsyncThunk(
  'enterprise/logoutEnterprise',
  async (_, { rejectWithValue }) => {
    const token = Cookies.get('entoken'); // قراءة التوكن الخاص بـ enterprise
    try {
      const response = await axios.get(`${URL}/enterprise/logout`, {
        headers: {
         token : token, // إرسال التوكن بالطريقة الخاصة بك
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error occurred during logout');
    }
  }
);

// تعريف الـ slice
const logoutEnterpriseSlice = createSlice({
  name: 'logoutEnterprise',
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutEnterprise.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(logoutEnterprise.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(logoutEnterprise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default logoutEnterpriseSlice.reducer;

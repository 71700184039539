import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie'; // Ensure you're using js-cookie to manage tokens
import { URL } from '../../config'; // Adjust the path to your config file if needed

// Async thunk to destroy a course
export const destroyEnterpriseCourse = createAsyncThunk(
  'enterpriseCourses/destroy',
  async (courseId, { rejectWithValue }) => {
    try {
      // Get the enterprise token from cookies
      const token = Cookies.get('entoken');

      // Send the GET request to destroy the course
      const response = await axios.get(`${URL}/enterprise/courses/${courseId}/destroy`, {
        headers: {
          token, // Include the token in the headers
        },
      });

      // Return the response data
      return response.data;
    } catch (error) {
      // Handle errors and return a meaningful error message
      return rejectWithValue(error.response?.data || 'Failed to destroy the course');
    }
  }
);

// Create the slice
const destroyEnterpriseCourseSlice = createSlice({
  name: 'destroyEnterpriseCourse',
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    resetDestroyState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(destroyEnterpriseCourse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(destroyEnterpriseCourse.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(destroyEnterpriseCourse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export const { resetDestroyState } = destroyEnterpriseCourseSlice.actions;
export default destroyEnterpriseCourseSlice.reducer;

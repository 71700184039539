import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../component/Footer/Footer';
import Logo from '../../../assets/images/lndexlogo_svg.svg';
import { instructorForgetPassword } from '../../../store/features/instructorForgetPasswordSlice'; // Import the Redux action

const ForgetInstructorPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  // Access Redux state to show loading, success, or error messages
  const { loading, status, message, error } = useSelector((state) => state.instructorForgetPassword);


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Check if the email field is empty
    if (!email.trim()) {
      setErrorMessage('Email field cannot be empty.');
      return;
    }

    // Clear any previous error message
    setErrorMessage('');
  // Dispatch the Redux thunk with the email
  dispatch(instructorForgetPassword(email))
  .unwrap()
  .then((response) => {
    if (response.status === true) {
      // Delay navigation by 2 seconds
      setTimeout(() => {
        navigate('/instructor-VerficationCode');
      }, 3000); // 2000ms = 3 seconds
    }
  })
  .catch((err) => {
    console.error('Error:', err);
  });
  };

  return (
    <div style={{ marginTop: '50px' }}>
      <div className='Registrition_Body_forget'>
        {/* Start signup body */}
        <div className='Signup_body'>
          <div className='Signup_body_Form'>
            <div className='Signup_body_Form_Sec1'>
              <img src={Logo} alt='Logo' />
            </div>
            <div className='Signup_body_Form_Sec2'>Forgot password</div>
            <div className='Signup_body_Form_Sec3'>
              <hr />
            </div>
            {/* Start input Field */}
            <div className='Signup_body_input_container'>
              {/* Email */}
              <div className='Signup_body_Form_Sec4'>
                <div>Email</div>
                <div className='Signup_body_input'>
                  <input
                    className='Sign_input'
                    type='email'
                    placeholder='example@gmail.com'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown} // Add onKeyDown event
                    required
                  />
                </div>
              </div>
            </div>
            {/* End input Field Container */}
            <div className='Registration_body_Form_Sec5'>
              <button
                className='Signup_body_Form_Sec5_button'
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    <span className='sr-only'> Sending...</span>
                  </>
                ) : (
                  'Send'
                )}
              </button>
            </div>
            {/* Display Error for Empty Field */}
            {errorMessage && <p className='error-message'>{errorMessage}</p>}
            {/* Display Success or Error Messages */}
            <div className='Signup_body_Form_Sec3'>
              <hr />
              {status && <p className='success-message'>{message}</p>}
              {error && <p className='ErrorMessage'>{error.msg}</p>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgetInstructorPassword;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../../store/features/InstructorUpdatePasswordSlice';
import unseeicon from '../../../assets/images/unseeicon.svg';
import seeicon from '../../../assets/images/seeicon.svg';
import { Link } from 'react-router-dom';

const ChangeInstructorPassword = () => {
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
  });

  const dispatch = useDispatch();
  const { loading, success, error, message } = useSelector((state) => state.updatePasswordinstructor);

  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setPasswords((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updatePassword({ old_password: passwords.current, new_password: passwords.new }));
  };

  return (
    <div className="ChangeInstructorPassword_Container">
      {/* Header */}
      <h2 className="ChangeInstructorPassword_Head">Change your Password</h2>

      {/* Form */}
      <form className="ChangeInstructorPassword_Form" onSubmit={handleSubmit}>
        {/* Current Password */}
        <div className="InputGroup">
          <label htmlFor="current">Current password</label>
          <div className="PasswordInput">
            <input
              type={showPassword.current ? 'text' : 'password'}
              id="current"
              placeholder="Password"
              value={passwords.current}
              onChange={handleInputChange}
              required
            />
            <button
              type="button"
              className="ToggleVisibility"
              onClick={() => togglePasswordVisibility('current')}
            >
              {showPassword.current ? <img src={seeicon} alt="seeicon" /> : <img src={unseeicon} alt="unseeicon" />}
            </button>
          </div>
        </div>

        {/* New Password */}
        <div className="InputGroup">
          <label htmlFor="new">New password</label>
          <div className="PasswordInput">
            <input
              type={showPassword.new ? 'text' : 'password'}
              id="new"
              placeholder="New password"
              value={passwords.new}
              onChange={handleInputChange}
              required
            />
            <button
              type="button"
              className="ToggleVisibility"
              onClick={() => togglePasswordVisibility('new')}
            >
              {showPassword.new ? <img src={seeicon} alt="seeicon" /> : <img src={unseeicon} alt="unseeicon" />}
            </button>
          </div>
        </div>

        <div className="ChangeInstructorPassword_Container_buttons">
          {/* Forgot Password */}
          <div className="ForgotPassword">
            <Link to="/instructor-forgot-password">Forgot your password?</Link>
          </div>

          {/* Submit Button */}
          <button type="submit" className="SaveChangesButton" disabled={loading}>
  {loading ? (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  ) : (
    'Save changes'
  )}
</button>
        </div>
    
      </form>

      {/* Feedback Messages */}
      {message && (
        <p className={success ? 'SuccessMessage' : 'ErrorMessage'}>
          {message}
        </p>
      )}
      {/* end Feedback Messages */}

    </div>
  );
};

export default ChangeInstructorPassword;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeEnterprisePassword } from '../../../store/enterpriseFeature/enterpriseChangePasswordSlice';
import Footer from '../../../component/Footer/Footer';
import Logo from '../../../assets/images/lndexlogo_svg.svg';
import PasswordInput from '../../../component/PasswordInput/PasswordInput';

const ChangeOtpPasswordEnterprise = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // استخراج كود OTP من الـ state المرسل عبر الـ navigate
  const otpCode = location.state?.code;

  // إدارة حالة الإدخالات
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationError, setValidationError] = useState('');

  // الوصول إلى حالة Redux
  const { isLoading, message, error } = useSelector((state) => state.enterpriseChangePassword) || {
    isLoading: false,
    message: null,
    error: null,
  };

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      setValidationError('Passwords do not match.');
      return;
    }

    // إذا كانت المدخلات متطابقة، أرسل الطلب
    setValidationError('');
    const data = {
      otp_code: otpCode,
      new_password: newPassword,
    };

    dispatch(changeEnterprisePassword(data)).then(() => {
      if (!error && message) {
        setTimeout(() => {
          navigate('/login');
        }, 1000);
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isLoading && otpCode && newPassword && confirmPassword) {
      handleSubmit();
    }
  };

  return (
    <div className="ChangeOtpPassword_Container">
      <div className="ChangeOtpPassword_Sec1">
        <img src={Logo} alt="Logo" />

        <label>Please Enter New Password</label>
        <PasswordInput
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter New Password"
          className="ChangeOtpPassword_Sec1_input"
        />

        <PasswordInput
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Retype password"
          className="ChangeOtpPassword_Sec1_input"
        />

        <div className="Registration_body_Form_Sec5">
          <button
            className="Signup_body_Form_Sec5_button"
            onClick={handleSubmit}
            disabled={isLoading || !otpCode || !newPassword || !confirmPassword}
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Sending...
              </>
            ) : (
              'Send'
            )}
          </button>
        </div>

        <div style={{ width: '100%', paddingTop: '10px' }}>
          {validationError && <p className="ErrorMessage">{validationError}</p>}
          {error && <p className="ErrorMessage">{error}</p>}
          {message && <p className="success-message">{message}</p>}
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ChangeOtpPasswordEnterprise;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserSavedCourses } from '../../../store/userFeature/userFetchSavedCourseSlice';
import { addCourseToCart } from '../../../store/userFeature/addCourseToCartSlice';
import { deleteSavedCourse } from '../../../store/userFeature/userDeleteFromSaveSlice';
import Loading from '../../../component/Loading/Loading';
import Clock from '../../../assets/images/Course/circle-clock1.png';
import Chair from '../../../assets/images/Course/Chair.png';
import Line from '../../../assets/images/Course/Line.png';
import Delete from '../../../assets/images/Course/cart_Delete.svg';
import Start from '../../../assets/images/Course/Star.png';
import AddToCart from '../../../assets/images/coursecard/shopping-cart-details.svg';
import SavedCompact from '../../../assets/images/Course/SavedCompact.svg';
import { fetchUserShowCart } from '../../../store/userFeature/showUserCartSlice';
import { motion } from 'framer-motion';

import './userSaved.css';

const UserSavedCourse = () => {
  const dispatch = useDispatch();

  // Get state from Redux store
  const { courses, loading, error } = useSelector((state) => state.userFetchSavedCourse);
  const { success, adding  , message } = useSelector((state) => state.userAddToCart);
  const { deleting, deleteSuccess } = useSelector((state) => state.userDeleteFromSave);

 console.log(success , adding)

  useEffect(() => {
    // Fetch saved courses on component mount
    dispatch(fetchUserSavedCourses());
  }, [dispatch]);

  useEffect(() => {
    // Refetch saved courses when a course is deleted successfully
    if (deleteSuccess) {
      dispatch(fetchUserSavedCourses());
    }
  }, [deleteSuccess, dispatch]);

  const handleAddToCartClick = (courseId , isEnterprise) => {
    dispatch(addCourseToCart({ courseId, isEnterprise }));
     // تحديث السلة من الـ API للحصول على البيانات المحدثة
  setTimeout(() => {
    dispatch(fetchUserShowCart());
  }, 500); // مهلة قصيرة للتأكد من اكتمال إضافة العنصر في الـ API
  };

  const handleDeleteClick = (courseId, isEnterprise) => {
    console.log("Deleting course with ID:", courseId);
    dispatch(deleteSavedCourse({ courseId, isEnterprise }));
  };
  

  // Loading state
  if (loading) return <Loading />;

  // Error state
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      {courses && courses.length === 0 ? (
        <p>No saved courses.</p>
      ) : (
        <div className="course-cards-container" style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {courses.map((course , index) => (
            <motion.div
              key={`${course.id}-${index}`} // Composite key
              className="Course_Container"
              style={{ width: '325px' }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              whileHover={{
                scale: 1.01,
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
              }}
              transition={{
                duration: 0.5,
                ease: 'easeInOut',
              }}
            >
              <div className="Course_Sec1">
                <img
                  className="Course_Sec1"
                  src={course?.image}
                  alt="CourseImg"
                  style={{ height: '250px', width: '325px' }}
                />
              </div>

              {course.is_enterprise && (
                <div style={{ marginTop: '-4px', color: 'red', fontWeight: 'bold' }}>
                  Enterprise
                </div>
              )}

              <div className="course-detail">
                <div className="Course_Sec3">{course?.title}</div>
                <div className="Course_Sec4" style={{ marginTop: '10px' }}>
                  <img src={Clock} alt="Clock" /> {course?.course_hours} &nbsp; ({course.session_count} sessions)
                </div>
                <div className="Course_Sec5">
                  <img src={Chair} alt="Chair" />
                  {course?.seats_count} seats left
                  <img src={Line} alt="Line" />
                  <img src={Start} alt="Start" />
                  {course?.rate ? course?.rate : '95%'} (234)
                </div>
                <div style={{ paddingTop: '8px', color: 'blue' }} className="Course_Sec6">
                  {course?.category?.title}
                </div>
                <div className="Course_Sec6">{course?.instructor?.name}</div>

                <div className="Course_Saved_in_Component" style={{ paddingTop: '30px' }}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(course.id, course.is_enterprise);
                    }}
                  >
                    {deleting ? (
                      <span>...</span>
                    ) : (
                      <img src={Delete} alt="Delete" className="Course_Saved_in_Component_icons" />
                    )}
                  </div>

                  <img src={SavedCompact} alt="SavedCompact" className="Course_Saved_in_Component_icons" />
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCartClick(course.id , course.is_enterprise);
                    }}
                  >
                    <img src={AddToCart} alt="addtocart_icon" className="Course_Saved_in_Component_icons" />
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      )}
      {success && <div className="success-message"> {message && <div>{message}</div>} </div>}
      {/*deleteSuccess && <div className="success-message">Course deleted successfully!</div> */}
    </div>
  );
};

export default UserSavedCourse;

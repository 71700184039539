import React, { useState } from 'react';
import './Archive.css';
import ArchiveCourse from './ArchiveCourse';
import ArchiveArticle from './ArchiveArticle';
import Line from '../../../../assets/images/Line.svg'
const Archive = () => {
  const [activeTab, setActiveTab] = useState('courses'); // Default to 'courses'

  return (
    <div className='ArchiveComponent_Container'>

<div className='Line_instractor_Dashboard'>
           <img src={Line} alt='Line' />
       </div>
      {/* Tab Switcher */}
      <div className='ArchiveComponent_Select'>
        <div
          className={activeTab === 'courses' ? 'active' : ''}
          onClick={() => setActiveTab('courses')}
        >
          Courses
        </div>
        <div
          className={activeTab === 'articles' ? 'active' : ''}
          onClick={() => setActiveTab('articles')}
        >
          Articles
        </div>
      </div>

      {/* Conditional Rendering */}
      <div className='ArchiveComponent_Content'>
        {activeTab === 'courses' ? <ArchiveCourse /> : <ArchiveArticle />}
      </div>
    </div>
  );
};

export default Archive;

// src/store/userFeature/userSavedCourseSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Adjust the import path as needed
import Cookies from 'js-cookie';

// Async thunk to save a course
export const saveCourse = createAsyncThunk(
  'user/saveCourse',
  async ({courseId, is_enterprise} , { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken'); // Retrieve token from cookies
      const response = await axios.post(
        `${URL}/user-courses-save`,
        { course_id: courseId , is_enterprise  },
        {
          headers: {
            token: token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to save course');
    }
  }
);

// Slice definition
const userSavedCourseSlice = createSlice({
  name: 'userSavedCourse',
  initialState: {
    saved: false, // Track whether the course is saved
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.saved = true; // Mark the course as saved on success
      })
      .addCase(saveCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSavedCourseSlice.reducer;

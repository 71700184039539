import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './CourseDetails.css'
import ArrowLeftGuide from '../../assets/images/ArrowLeft.svg'
import { Link } from 'react-router-dom'
import CoursedImage from '../../assets/images/Course/coursedetils.png'
import coursesaved from '../../assets/images/Course/Saved.svg'
import calenderGoogle from '../../assets/images/Course/calendar-add (1).svg'
import Description from '../../component/CourseDeatils/Description'
import Content from '../../component/CourseDeatils/Content'
import CourseLocation from '../../component/CourseDeatils/CourseLocation'
import Review from '../../component/CourseDeatils/Review'
/* start import icon for cart */
import datestart from '../../assets/images/coursecard/blank-calendar.svg'
import ShopingCart from '../../assets/images/coursecard/shopping-cart-details.svg'
//import giveGift from '../../assets/images/coursecard/give-gift.svg'
import beginner from '../../assets/images/coursecard/beginner.svg'
import Line from '../../assets/images/coursecard/LineCourseCard.svg'
import Hours from '../../assets/images/coursecard/clock.svg'
import Chairs from '../../assets/images/coursecard/sets.svg'
import Certificate from '../../assets/images/coursecard/certificate.svg'
import Arabic from '../../assets/images/coursecard/volume.svg'
import Star from '../../assets/images/coursecard/Star.svg'
import InstructorIcon from '../../assets/images/instructors/instructor1.png'
import Course from '../../component/Course/Course'
import LeftArrow from '../../assets/images/leftarrow.png'
import RightArrow from '../../assets/images/rightarrow.png'
import YouTubePlayer from '../../component/YoutubePlayer/YoutubePlayer';
import Loading from '../../component/Loading/Loading';


const CourseDetails = () => {

  const { id } = useParams(); // استخراج المعرف من الرابط
  const courses = useSelector((state) => state.GetCourse.list); // جلب قائمة الدورات من المخزن

  console.log('Course ID:', id);
  console.log('All Courses:', courses);


   // تحويل المعرف إلى نوع رقم للتأكد من تطابقه مع نوع البيانات في القائمة
   const courseId = parseInt(id, 10);
   // البحث عن الدورة باستخدام المعرف
   const course = courses.find((course) => course.id === courseId);

  console.log('Found Course:', course); // تحقق من أن الكورس تم العثور عليه
  //console.log('video', course?.promo_video_link)



    const courseContainerRef = useRef(null);
    const scrollLeft = () => {
        if (courseContainerRef.current) {
            courseContainerRef.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scrollRight = () => {
        if (courseContainerRef.current) {
            courseContainerRef.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
      // using instructor Course Ref 
    const  CoursesByInstructor = useRef(null)
      const scroll_Instructor_Course_Left = () => {
        if (CoursesByInstructor.current) {
          CoursesByInstructor.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scroll_Instructor_Course_Right = () => {
        if (CoursesByInstructor.current) {
          CoursesByInstructor.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
     // Check Box 
    const [selectedDate, setSelectedDate] = useState('11 October, 8 pm');
    const handleChange = (event) => {
      setSelectedDate(event.target.value);
    };
    // To active Section 4 Item 
    
   const [activeSection , setActiveSection]= useState('Description')
   const renderSection = () => {
    switch (activeSection) {
      case 'Description':
        return <Description course={course}/>;
      case 'Content':
        return   <Content  course={course.content_data}/> ;
      case 'Course Location':
        return <CourseLocation />;
      case 'Review':
        return <Review />;
      default:
        return null;
    }
  };
   function clicktest() {
    console.log('click test ')
   }
   console.log(course);
   console.log(course?.promo_video_link);

  return (
    <div>
       {/* start Course details Contianer */}
          <div className='CourseDetails_Container'>
          <div className='CourseDetails_Sec1'>
            <Link to='/' className='link'>  <div>home</div> </Link>
               <div><img src={ArrowLeftGuide} alt='arrow'/></div>
               <div>Course details</div>
            </div>
             {/* start section 2  */}
             <div className='CourseDetails_Sec2'>
                
                  {/* start part 2  */}
                  <div className='CourseDetails_Sec2_Part2'>
               
                       <div style={{display:'flex',flexDirection:'column'}}>
                       <span className='CourseDetails_Sec2_Part1'>
                  {course?.title}
                      </span>
                     {/* Pass the video URL     */}
                     {course ? (
  course?.promo_video_link? (
    <YouTubePlayer videoUrl={course?.promo_video_link} />
  ) : (
    <img
      src={course?.image || 'default-placeholder.png'}
      alt="course"
      style={{ width: '650px', maxHeight: '700px' }}
    />
  )
) : (
  <Loading />
)}


                           {/* <img src={course?.image} alt='immagecourse'></img>*/}
                            <div className='CourseDetails_Sec2_Part2_elementSaveCourse'>
                                   <div className='CourseDetails_Sec2_Part2_elementSaveCourse_item'>
                                       <img src={coursesaved} alt='coursesaved'></img> 
                                       <div>save course</div>
                                   </div>
                                    {/* 2 */}
                                    <div className='CourseDetails_Sec2_Part2_elementSaveCourse_item'>
                                        <img src={calenderGoogle} alt='calenderGoogle'></img>
                                        <div>add to your google calender</div>
                                    </div>
                            </div>
                          
                              {/* Start  Course Content Section */}
                       <div className='CourseDetails_Sec2_Part2_CourseHeader'>
                       <div className='CourseHeader'>
                            <div className={activeSection === 'Description' ? 'active' : '' }
                            onClick={() => setActiveSection('Description')}
                            >Description</div>
                            <div className={activeSection === 'Content'? 'active' : '' }
                            onClick={() => setActiveSection('Content')}
                            >Content</div>
                            <div className={activeSection === 'Course Location' ? 'active' : '' }
                            onClick={() => setActiveSection('Course Location')}
                            >Course Location</div>
                            <div className={activeSection === 'Review'? 'active' : '' }
                            onClick={() => setActiveSection('Review')}
                            >Review</div>
                       </div>
                       <div className='CourseHeader_Body'>  
                         {renderSection()}
                       </div>
                       </div>
                       </div>
                
                       {/* Second Element  */}
                       <div className='CourseDetails_Sec2_Part2_CartElement'>
                             <div className='CourseDetails_Sec2_Part2_CartElement_part1'>
                                 <div className='CartElement_Date'><img src={datestart} alt='datestart'/>Start Date</div>
                                 <div className="date-picker">
                                      {/* first option date */}
                                    <div className="date-option">
                                          <input
                                            type="checkbox"
                                            checked={selectedDate === '11 October, 8 pm'}
                                            onChange={handleChange}
                                            value="11 October, 8 pm"
                                          />
                                          <span className={`date-text ${selectedDate === '11 October, 8 pm' ? 'selected' : ''}`}>
                                            11 October, 8 pm
                                          </span>
                                        </div>
                                             {/* second option date */}
                                    <div className = {`date-option ${selectedDate} ===  ? 'selected':''`}>
                                          <input
                                            type="checkbox"
                                            checked={selectedDate === '23 October, 8 pm'}
                                            onChange={handleChange}
                                            value="23 October, 8 pm"
                                          />
                                          <span className={`date-text ${selectedDate === '23 October, 8 pm' ? 'selected' : ''}`}>
                                            23 October, 8 pm
                                          </span>
                                        </div>
                                             {/* third option date */}
                                      <div className="date-option">
                                          <input
                                            type="checkbox"
                                            checked={selectedDate === '1 November, 8 pm'}
                                            onChange={handleChange}
                                            value="1 November, 8 pm"
                                          />
                                          <span className={`date-text ${selectedDate === '1 November, 8 pm' ? 'selected' : ''}`}>
                                          1 November, 8 pm
                                          </span>
                                         </div>
                                  </div>
                                  {/* start price  */}
                                  <div className='CartElement_ThePrice'>
                                       <div>THE PRICE:</div>
                                       <span>{course?.price} EGP</span>
                                  </div>
                                       {/* buttons  */}
                                       <div className='CartElement_button_B' onClick={clicktest}>
                                         <div className='CartElement_button_Book'>Book now</div>
                                       </div>
                                         {/* Button Add Cart  */}
                                         <div className='CartElement_button_C' onClick={clicktest}>
                                           <div className='CartElement_button_Cart'><img src={ShopingCart} alt='ShopingCart'/>Add to cart</div>
                                         </div>
                                              {/* Button Add Cart  */}
                                               {/* 
                                          <div className='CartElement_buy' onClick={clicktest}>
                                           <div className='CartElement_buy_Content'><img src={giveGift} alt='giveGift'/>Buy as a gift</div>
                                         </div>
                                         */}
                                            <div style={{display:'flex',justifyContent:'center'}}><img style={{width:'300px'}} src={Line} alt='Line' /></div>
                                                {/*  Start A card Details    */}
                                                <div className='CartElement_Details'>
                                                       <div className='CartElement_Details_item'><img src={beginner} alt='beginner'/>{course?.level?.name}</div>
                                                       <div className='CartElement_Details_item'><img src={Hours} alt='beginner'/>{course?.course_hours} hours ({course?.session_count} sessions)</div>
                                                       <div className='CartElement_Details_item'><img src={Chairs} alt='Chairs'/>{course?.seats_count} seats left</div>
                                                       <div className='CartElement_Details_item'><img src={Certificate} alt='Certificate'/>{course?.has_certicate === 1  ? 'Certificate' : 'No Certificate'}</div>
                                                       <div className='CartElement_Details_item'><img src={Arabic} alt='Arabic'/>{course?.lang ? 'Arabic':'English'}   </div>
                                                       <div className='CartElement_Details_item'><img src={Star} alt='Star'/>{course?.rate } 95% (234)</div>
                                               </div>
                              </div>
                             <div className='Insructor_CardElement'>
                               <div className='Insructor_CardElement_Title'>Instructor</div>
                               <div className='Insructor_CardElement_information'>
                                     <div><img style={{width:'80px'}} src={course?.instructor?.profile_image} alt='InstructorIcon'/></div>
                                        {/* Second_Part  */}
                                     <div className='Insructor_CardElement_information_Part2'>
                                        <div className='Insructor_CardElement_information_Part2_Name'>{course?.instructor?.name}</div>
                                        <div className='Insructor_CardElement_information_Part2_major'>{course?.instructor?.career}</div>
                                        <div className='Insructor_CardElement_information_Part2_Date'>Joined June 2021</div>
                                        <div className='Insructor_CardElement_information_Part2_star'><img style={{width:'15px',height:'15px'}} src={Star} alt='Star'/> 95% (234)</div>
                                     </div>
                                        
                               </div>
                               {/* Third_Part  */}
                               <div className='Insructor_CardElement_information_button'>Follow</div>
                             </div>
                       </div>
                  </div>
               

             </div>
               {/* Similar Classes */}
                 {/*  The Course Header  */}
        <div className='Home_Sec3'>
            <div ><div className='SimilarClasses_Title'>Similar Classes</div></div>
            <div className='Home_Sec3_Part2'>
            <img src={LeftArrow} alt='ArrowLeft' onClick={scrollLeft}  />
            <img src={RightArrow} alt='ArrowRight' onClick={scrollRight}  />
            </div>
        </div>
               
                {/*  The Course Body   */}
           <div className='Home_Sec4' ref={courseContainerRef}>
              <Course />
              <Course />
              <Course />
              <Course />
              <Course />
              <Course />
           </div>

            {/*  The Course Header  
        <div className='Home_Sec3'>
            <div ><div className='SimilarClasses_Title'>More Courses by Muhammed Ibrahim</div></div>
            <div className='Home_Sec3_Part2'>
            <img src={LeftArrow} alt='ArrowLeft' onClick={scroll_Instructor_Course_Left}  />
            <img src={RightArrow} alt='ArrowRight' onClick={scroll_Instructor_Course_Right}  />
            </div>
        </div>
               */}
                {/*  The Course Body   
           <div className='Home_Sec4' ref={CoursesByInstructor}>
              <Course />
              <Course />
              <Course />
              <Course />
              <Course />
              <Course />
           </div>
           */}
          </div> {/* end container */}
    </div>
  )
}
export default CourseDetails
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Thunk to handle unarchiving a course
export const unarchiveEnterpriseCourse = createAsyncThunk(
  'enterprise/unarchiveCourse',
  async (courseId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('entoken'); // Retrieve the enterprise token
      const response = await axios.get(`${URL}/enterprise/courses/${courseId}/unarchive`, {
        headers:{
            token,
        }  
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const unarchiveEnterpriseCourseSlice = createSlice({
  name: 'unarchiveEnterpriseCourse',
  initialState: {
    loading: false,
    successMessage: null,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.successMessage = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(unarchiveEnterpriseCourse.pending, (state) => {
        state.loading = true;
        state.successMessage = null;
        state.error = null;
      })
      .addCase(unarchiveEnterpriseCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message;
        state.error = null;
      })
      .addCase(unarchiveEnterpriseCourse.rejected, (state, action) => {
        state.loading = false;
        state.successMessage = null;
        state.error = action.payload;
      });
  },
});

export default unarchiveEnterpriseCourseSlice.reducer;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpCode, resetOtpVerification } from "../../store/userFeature/otpVerificationSlice"; // Adjust path
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer/Footer";
import Logo from '../../assets/images/lndexlogo_svg.svg';

const UserVerifyEmail = () => {
  const [inputs, setInputs] = useState(["", "", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(200); // Countdown timer
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access Redux state for user check forget code
  const { isLoading, error, success } = useSelector((state) => state.userotpVerification);

  // Timer countdown effect
  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      setIsResendDisabled(false); // Enable resend after timer ends
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Handle input change
  const handleChange = (index, value) => {
    // If the input is more than 1 character, handle pasting
    if (value.length > 1) {
      const newInputs = [...inputs];
      // Split the pasted value into an array of digits
      const digits = value.split("").slice(0, 5); // Limit to first 5 digits
      digits.forEach((digit, idx) => {
        if (index + idx < inputs.length) {
          newInputs[index + idx] = digit; // Update the corresponding input
        }
      });
      setInputs(newInputs);
      // Focus the last input after pasting
      inputRefs.current[Math.min(index + digits.length, inputs.length - 1)].focus();
      return; // Exit to prevent further processing
    }

    // Normal single character input
    if (value.length > 1) return; // Prevent more than 1 digit
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    // Move to the next input if the user types
    if (value && index < 4) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault(); // منع السلوك الافتراضي للصق
    const pasteData = e.clipboardData.getData("text").slice(0, 5); // أخذ أول 5 أرقام من النص الملصق
    const newInputs = pasteData.split(""); // تحويل النص إلى مصفوفة
    const updatedInputs = [...inputs];
  
    newInputs.forEach((digit, idx) => {
      if (idx < inputs.length) {
        updatedInputs[idx] = digit;
      }
    });
  
    setInputs(updatedInputs);
  
    // التركيز على آخر حقل تم ملؤه
    const lastIndex = Math.min(newInputs.length, inputs.length - 1);
    inputRefs.current[lastIndex]?.focus();
  };
  

  // Handle Backspace
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !inputs[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    // Check for Enter key
    if (e.key === "Enter") {
      handleSubmit(); // Execute submit on Enter
    }
  };

  // Resend Code Handler
  const handleResend = () => {
    setInputs(["", "", "", "", ""]);
    setTimeLeft(200);
    setIsResendDisabled(true);
    console.log("Resending Verification Code...");
    // You may want to add logic to actually resend the code here
  };

  // Submit Code Handler
  const handleSubmit = () => {
    const code = inputs.join("");
    if (code.length === 5) {
      dispatch(verifyOtpCode(code)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          // Navigate on success
          setTimeout(() => {
            navigate('/home');
          }, 1000); // 1000ms = 1 second
        } else {
          // Handle error (already done via Redux state)
          console.error("Verification failed:", action.payload);
        }
      });
    } else {
      alert("Please enter a 5-digit code.");
    }
  };

  return (
    <div className="Otp-Container-page">
      <div className="verification-container">
        <img src={Logo} alt="lndex Logo" />
        <div className="Otp-info-section">
          <p className="subtitle">Enter Verification Code</p>
          <p className="instruction">Please Check Your E-mail For Verification Code?</p>
        </div>

        {/* Code Input Fields */}
        <div className="code-inputs">
          {inputs.map((input, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={input}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(el) => (inputRefs.current[index] = el)}
              onPaste={handlePaste} // إضافة الحدث هنا
              className="code-input"
            />
          ))}
        </div>

        {/* Timer & Resend */}
        <div className="timer">
          {timeLeft > 0 ? (
            <span className="time-left">{timeLeft} Sec</span>
          ) : (
            <button
              onClick={handleResend}
              disabled={isResendDisabled}
              className="resend-btn"
            >
              Resend Verification Code
            </button>
          )}
        </div>

        {/* Submit Button */}
        <div className="otp-sent-container">
          <button onClick={handleSubmit} className="send-btn" disabled={isLoading}>
            {isLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
                <span className='sr-only'> Sending...</span>
              </>
            ) : (
              "Send"
            )}
          </button>
        </div>

        {/* Display Success Message */}
        {success  && (
          <p className='success-message'>{success}</p>
        )}

        {/* Display Error Message */}
        {error && (
          <p className='ErrorMessage'>
            {typeof error === "string" ? JSON.stringify(error) : error.msg}
          </p>
        )}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UserVerifyEmail;

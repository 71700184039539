import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import ArtImage from '../../assets/images/ArtImage.svg';
import logo from '../../assets/images/lndexlogo_svg.svg'
import Loading from '../../component/Loading/Loading';
import './Article_details.css';
import { motion } from 'framer-motion'; // Import motion from framer-motion
import UserNavbar from '../UserNavbar/UserNavbar';
import UnauthAppbar from '../AppbarUnAuth/UnauthAppbar';
import InstructorNav from '../InstructorNav/InstructorNav';
import Footer from '../Footer/Footer';



const ArticleDetails = () => {
  const { id } = useParams(); // Get article ID from URL
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [scrollProgress, setScrollProgress] = useState(0); // Track scroll progress
  const authToken = Cookies.get('authToken');
  const instoken = Cookies.get('Instructor_Token');

  useEffect(() => {
    const fetchArticleDetails = async () => {
      try {
        const response = await axios.get(`https://dash.lndex.io/api/articale/${id}`, {
          headers: {
            token: authToken,
          },
        });
        console.log('Article details response:', response.data); // Inspect response data
        setArticle(response.data); // Set article data
      } catch (error) {
        console.error('Error fetching article details:', error);
      } finally {
        setLoading(false); // Ensure loading stops after the request
      }
    };

    fetchArticleDetails();
  }, [id, authToken]);

  // Track scroll position and update scroll progress
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = window.scrollY;
      const progress = (scrollPosition / scrollHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (loading) {
    return <Loading />; // Show loading message while fetching
  }

  if (!article) {
    return <div>Article not found</div>; // Handle case where no article is found
  }

  return (
    <div>
      {instoken ? (
        <InstructorNav />
      ) : authToken ? (
        <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}> <Link to='/home' className='link'><img src={logo} alt='logo'/></Link>  <UserNavbar /></div>
      ) : (
        <UnauthAppbar />
      )}

      <div className="scroll-progress-bar" style={{ width: `${scrollProgress}%` }} />

      <div className="ArticleById_Container">
        {/* Scroll animation for the title */}
        <motion.div
          className="ArticleById_Sec1"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.5 }}
        >
          <div className="ArticleById_Header">{article.data.article.title}</div>
        </motion.div>

        {/* Scroll animation for instructor and date */}
        <motion.div
          className="ArticleById_Sec2"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.5 }}
        >
          <div>
            <img
              src={article.data.article.instructor.profile_image}
              alt="Instructor"
              className="Article_Instructor_picture"
            />
          </div>
          <div>
            <div className="ArticleById_Sec2_Name">{article.data.article.instructor.name}</div>
            <div className="ArticleById_Sec2_Date">{article.data.article.publish_date}</div>
          </div>
        </motion.div>

        {/* Scroll animation for article image */}
        <motion.div
          className="ArticleById_Sec3"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.5 }}
        >
          <img
            src={article.data.article.image}
            alt="ArtImage"
            className="Article_Image_From_Api"
          />
        </motion.div>

        {/* Scroll animation for article description */}
        <motion.div
          className="ArticleById_Sec4"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="ArticleById_Sec4_Title"></div>
          <div
            className="ArticleById_Sec4_Paraghraph"
            dangerouslySetInnerHTML={{
              __html: article.data.article.description,
            }}
          ></div>
        </motion.div>
      </div>

      <Footer />
    </div>
  );
};

export default ArticleDetails;

// Example Redux slice setup
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getCourseById = createAsyncThunk('course/getById', async (id) => {
    const response = await fetch(`{{URL}}/courses/${id}`);
    if (!response.ok) {
        throw new Error('Failed to fetch course');
    }
    return await response.json();
});

const courseSlice = createSlice({
    name: 'GuestGetCourse',
    initialState: { course: null, loading: false, error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCourseById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCourseById.fulfilled, (state, action) => {
                state.loading = false;
                state.course = action.payload;
            })
            .addCase(getCourseById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default courseSlice.reducer;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { URL } from '../../../config';

const CourseLevelSelector = ({ selectedLevel, onLevelChange }) => {
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const response = await axios.get(`${URL}/leveles`);
        console.log('API Response:', response.data); // Log the response to check the structure
        
        // Ensure to access the correct path
        const formattedLevels = response.data.data?.leveles.map((level) => ({
          id: level.id,
          label: level.name,
        })) || []; // Use optional chaining and fallback to an empty array

        console.log('Formatted Levels:', formattedLevels); // Log the formatted levels
        setLevels(formattedLevels);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLevels();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="course-level-selector">
      <select
        value={selectedLevel}
        onChange={(e) => onLevelChange(e.target.value)} // Pass the selected level ID
        className="level-dropdown"
        style={{width:'320px',height:'40px',padding:'8px',border:'none', backgroundColor:'#EDEFFF',borderRadius:'8px'}}
      >
          <option value="" disabled>
            Select level
         </option>
        {levels.map((level) => (
          <option key={level.id} value={level.id}>
            {level.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CourseLevelSelector; 
import React , { useState } from 'react'
import ArrowUp from '../../assets/images/Arrowup.svg';
import lineContent from '../../assets/images/lineContent.svg';

const Description = ({course}) => {

  const [openSections, setOpenSections] = useState({});

  // Toggle a specific section
  const toggleSection = (index) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };


  //console.log("Test Milestone data ILOS : ", course.milestone_data[1])
  const [Expaned , setExpaned ] = useState(false)   
  const ToggleExpaned = () => {
      setExpaned(!Expaned)
  }

 // Utility function to strip HTML tags to convert it as String 
 const stripHtmlTags = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};


  return (
    <div className='Description_Container'>
        <div className='Description_Sec1'>
           <div className='Description_Sec1_header'>Description</div>
           <div className='Description_Sec1_header_body'>
             This all description about this course if you want to read press readmore 
        
           {Expaned && (
             <div>
                {course.description}
             </div>
           )}
            <div onClick={ToggleExpaned} className='Description_Sec1_Expaned'>{Expaned ? 'read less' : 'read More'}</div>
           </div>
          
        </div>
         {/* Start To Design Section 2  */}
         <div className='Description_Sec2'>
               <div className='Description_Sec2_header'>Upon completion of this course, students will be able to:</div>
               <div className='Description_Sec2_List'>
                 <ul>
                 {Array.isArray(course?.milestone_data) && course.milestone_data.length > 0 ? (
              course.milestone_data.map((milestone, index) => (
                <li key={milestone.id || index}>
                  {stripHtmlTags(milestone.details)}
                </li>
              ))
            ) : (
              <li>No ILOS available..</li>
            )}
                 </ul>
               </div>

                     {/* FAQS  */}
               <div className='Description_Sec2_header'>
               FAQs about this course
               </div>
                
               <div className="CourseDetails_Content_Container">
        {course?.faqs.map((section, index) => (
          <div key={index} className="CourseDetails_Content_Sec1">
            <div
              onClick={() => toggleSection(index)}
              className="CourseDetails_Content_question"
            >
              <h2
                dangerouslySetInnerHTML={{
                  __html: section.question
                }}
              />
              <div style={{ marginLeft: '10px' }}>
                <img
                  src={ArrowUp}
                  alt="ArrowToggle"
                  style={{
                    transform: openSections[index] ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.3s',
                  }}
                />
              </div>
            </div>
            <div>
              <img src={lineContent} alt="lineContent" />
            </div>
            {openSections[index] && (
              <div
                className="CourseDetails_Content_Answer"
                dangerouslySetInnerHTML={{ __html: section.answer }}
              />
            )}
          </div>
        ))}
      </div>


         </div>
    </div>
  )
}

export default Description
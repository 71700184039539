import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';
// API Endpoint
const API_URL = `${URL}/enterprise/change-password`;

// Async thunk for changing the password
export const changeEnterprisePassword = createAsyncThunk(
  'enterprise/changePassword',
  async ({ otp_code, new_password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(API_URL, {
        otp_code,
        new_password,
      });
      return response.data; // Return the full response data
    } catch (error) {
      // Extract and return the error message from the response
      const errorMessage = error.response?.data?.msg || 'Something went wrong';
      return rejectWithValue(errorMessage);
    }
  }
);

// Slice definition
const enterpriseChangePasswordSlice = createSlice({
  name: 'enterpriseChangePassword',
  initialState: {
    isLoading: false,
    success: false,
    error: null,
    message: null, // Store the success or error message
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeEnterprisePassword.pending, (state) => {
        state.isLoading = true;
        state.success = false;
        state.error = null;
        state.message = null;
      })
      .addCase(changeEnterprisePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = action.payload.status; // Use the `status` field
        state.message = action.payload.msg; // Use the `msg` field
      })
      .addCase(changeEnterprisePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload; // Use the error message
        state.message = null; // Clear any previous messages
      });
  },
});

export default enterpriseChangePasswordSlice.reducer;

import React, { useState } from 'react';
import ArrowUp from '../../assets/images/Arrowup.svg';
import lineContent from '../../assets/images/lineContent.svg';

const Content = ({ course }) => {
  const [openSections, setOpenSections] = useState({});

  // Toggle a specific section
  const toggleSection = (index) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="CourseDetails_Content_Container">
      <div className="CourseDetails_Content_Container">
        {course.map((section, index) => (
          <div key={index} className="CourseDetails_Content_Sec1">
            <div
              onClick={() => toggleSection(index)}
              className="CourseDetails_Content_question"
            >
              <h2
                dangerouslySetInnerHTML={{
                  __html: section.title,
                }}
              />
              <div style={{ marginLeft: '10px' }}>
                <img
                  src={ArrowUp}
                  alt="ArrowToggle"
                  style={{
                    transform: openSections[index] ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.3s',
                  }}
                />
              </div>
            </div>
            <div>
              <img src={lineContent} alt="lineContent" />
            </div>
            {openSections[index] && (
              <div
                className="CourseDetails_Content_Answer"
                dangerouslySetInnerHTML={{ __html: section.details }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Content;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk for updating the password
export const updatePassword = createAsyncThunk(
  'instructor/updatePassword',
  async ({ old_password, new_password }, { rejectWithValue }) => {
    try {
      const token = Cookies.get('Instructor_Token');
      const response = await axios.post(
        `${URL}/instructor/update-password`,
        { old_password, new_password },
        {
          headers: {
            token: token,
          },
        }
      );
      return response.data; // Success response
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

// Slice for handling password update
const updatePasswordSlice = createSlice({
    name: 'updatePassword',
    initialState: {
      loading: false,
      success: false,
      error: null,
      message: '', // Store message
    },
    reducers: {
      resetState: (state) => {
        state.loading = false;
        state.success = false;
        state.error = null;
        state.message = ''; // Reset message
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(updatePassword.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.success = false;
          state.message = ''; // Clear any previous messages
        })
        .addCase(updatePassword.fulfilled, (state, action) => {
          state.loading = false;
          state.success = true;
          state.error = null;
          state.message = action.payload.msg || 'Password updated successfully!';
        })
        .addCase(updatePassword.rejected, (state, action) => {
          state.loading = false;
          state.success = false;
          state.error = action.payload;
          state.message = action.payload?.msg || 'An error occurred!';
        });
    },
  });

export const { resetState } = updatePasswordSlice.actions;
export default updatePasswordSlice.reducer;

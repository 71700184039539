import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkForgetCode } from "../../../store/enterpriseFeature/checkForgetCodeSlice"; // Import the action
import { useNavigate } from "react-router-dom";
import Footer from "../../../component/Footer/Footer";
import Logo from "../../../assets/images/lndexlogo_svg.svg";

const OtpEnterprise = () => {
  const [inputs, setInputs] = useState(["", "", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(200);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, status, message, error } = useSelector(
    (state) => state.checkForgetCode // Ensure the state is accessed correctly
  );

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const handleChange = (index, value) => {
    if (value.length > 1) return;
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    if (value && index < 4) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !inputs[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").trim();
  
    if (/^\d{5}$/.test(pastedData)) {
      const newInputs = pastedData.split("");
      setInputs(newInputs);
  
      newInputs.forEach((digit, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].value = digit;
        }
      });
      inputRefs.current[4]?.focus();
    } else {
      alert("Please paste a valid 5-digit OTP code.");
    }
  };

  const handleResend = () => {
    setInputs(["", "", "", "", ""]);
    setTimeLeft(200);
    setIsResendDisabled(true);
    console.log("Resending Verification Code...");
  };

  const handleSubmit = () => {
    const code = inputs.join("");
    console.log(code)
    if (code.length === 5) {
      dispatch(checkForgetCode(code)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          setTimeout(() => {
            navigate("/otp-changepassword-enterprise", { state: { code } });
          }, 3000);
        } else {
          console.error("Verification failed:", action.payload);
        }
      });
    } else {
      alert("Please enter a 5-digit code.");
    }
  };

  return (
    <div className="Otp-Container-page">
      <div className="verification-container">
        <img src={Logo} alt="lndex Logo" />
        <div className="Otp-info-section">
          <p className="subtitle">Enter Verification Code</p>
          <p className="instruction">
            Please Check Your E-mail For Verification Code?
          </p>
        </div>

        <div className="code-inputs">
          {inputs.map((input, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={input}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={index === 0 ? handlePaste : null}
              ref={(el) => (inputRefs.current[index] = el)}
              className="code-input"
            />
          ))}
        </div>

        <div className="timer">
          {timeLeft > 0 ? (
            <span className="time-left">{timeLeft} Sec</span>
          ) : (
            <button
              onClick={handleResend}
              disabled={isResendDisabled}
              className="resend-btn"
            >
              Resend Verification Code
            </button>
          )}
        </div>

        <div className="otp-sent-container">
          <button onClick={handleSubmit} className="send-btn" disabled={loading}>
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only"> Sending...</span>
              </>
            ) : (
              "Send"
            )}
          </button>
        </div>

        {status && message && <p className="success-message">{message}</p>}
        {error && (
          <p className="ErrorMessage">
            {typeof error === "string" ? error : error.msg}
          </p>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default OtpEnterprise;

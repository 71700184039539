import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const RegistrationSuccess = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      style={styles.container}
    >
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.7, ease: 'easeOut' }}
        style={styles.card}
      >
        <h1 style={styles.title}>Registration Successful!</h1>
        <p style={styles.message}>
          Thank you for registering as an Lndex Partner. Please wait for your account to be activated by our system. We’ll notify you via email once it’s ready.
        </p>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7, duration: 0.5 }}
          style={styles.buttonContainer}
        >
          <Link to="/login" style={styles.link}>
            <motion.button
              whileHover={{ scale: 1.1, backgroundColor: '#3348d8' }}
              whileTap={{ scale: 0.9 }}
              style={styles.button}
            >
              Login
            </motion.button>
          </Link>
          <Link to="/" style={styles.link}>
            <motion.button
              whileHover={{ scale: 1.1, backgroundColor: '#3348d8' }}
              whileTap={{ scale: 0.9 }}
              style={styles.button}
            >
              Go to Home
            </motion.button>
          </Link>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f8fa', // Light background for contrast
    padding: '0 20px',
  },
  card: {
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: '30px 20px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    width: '100%',
  },
  title: {
    color: '#3F52FC', // Brand color
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  message: {
    color: '#333', // Neutral text color
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  button: {
    backgroundColor: '#3F52FC', // Brand color
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    padding: '10px 20px',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'all 0.5s ease-in-out',
  },
  link: {
    textDecoration: 'none',
  },
};

export default RegistrationSuccess;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

export const fetchInstructorArticles = createAsyncThunk(
  'instructorArticles/fetchArticles',
  async ({ is_archived }, { rejectWithValue }) => {
    const token = Cookies.get('Instructor_Token');
    try {
      const response = await axios.post(
        `${URL}/instructor-articles`,
        { is_archived: is_archived }, // Sending the body
        {
          headers: {
            token: token, // Add token to the Authorization header
          },
        }
      );
      console.log('API Response unarchive :', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const instructorArticlesSlice = createSlice({
  name: 'instructorArticles',
  initialState: {
    articles: [],
    status: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructorArticles.pending, (state) => {
        state.status = 'loading';
      })
  // Update the fulfilled case to match the API response structure
.addCase(fetchInstructorArticles.fulfilled, (state, action) => {
  state.status = 'succeeded';
  state.articles = action.payload.data.articles; // Update this line to match the correct path
})
      .addCase(fetchInstructorArticles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.msg;
      });
  },
});

export default instructorArticlesSlice.reducer;
// src/redux/slices/userUpdateProfileSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';
import Cookies from 'js-cookie';

// async thunk لتحديث البروفايل
export const userUpdateProfile = createAsyncThunk(
  'user/userUpdateProfile',
  async (imageFile, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken'); // استرجاع الـ token من الـ cookies

      // تجهيز الـ FormData
      const formData = new FormData();
      formData.append('image', imageFile); // إضافة الصورة (يمكنك استخدام اسم متغير أكثر دلالة)

      // إرسال طلب POST لتحديث البروفايل
      const response = await axios.post(`${URL}/update-profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // تحديد النوع
          token: token, // إرسال الـ token في الهيدر
        },
      });

      return response.data; // إرجاع البيانات في حالة النجاح
    } catch (error) {
      console.error('Error updating profile:', error); // تسجيل الخطأ
      return rejectWithValue(error.response?.data?.message || 'Failed to update profile'); // في حالة الخطأ، إرجاع الرسالة
    }
  }
);

// إنشاء Slice
const userUpdateProfileSlice = createSlice({
  name: 'userUpdateProfile',
  initialState: {
    loading: false,
    error: null,
    success: false,
    // يمكنك إضافة متغير لتخزين رابط الصورة الجديد إذا لزم الأمر
    // imageUrl: null,
  },
  reducers: {
    resetUserProfileUpdate: (state) => {
      // إعادة تعيين حالة التحديث
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userUpdateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(userUpdateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true; // نجاح التحديث
        // هنا يمكنك تخزين البيانات إذا لزم الأمر
        // state.imageUrl = action.payload.imageUrl; // مثال لتخزين رابط الصورة
      })
      .addCase(userUpdateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // رسالة الخطأ
      });
  },
});

export const { resetUserProfileUpdate } = userUpdateProfileSlice.actions;
export default userUpdateProfileSlice.reducer;

// src/redux/slices/enterpriseProfileSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { URL } from '../../config'; // رابط الـ API

// Define the async thunk
export const fetchEnterpriseProfile = createAsyncThunk(
  "enterprise/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get("entoken"); // جلب التوكن من الكوكيز
      const response = await axios.get(`${URL}/enterprise/profile`, {
        headers: 
        { 
            token: token ,
        }, // إضافة التوكن في الهيدرز
      });
      //console.log("API Response:", response.data);

      return response.data; // استخراج بيانات البروفايل
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Failed to fetch enterprise profile"
      );
    }
  }
);

// Create the slice
const enterpriseProfileSlice = createSlice({
  name: "enterpriseProfile",
  initialState: {
    profile: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearProfile: (state) => {
      state.profile = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnterpriseProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEnterpriseProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchEnterpriseProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearProfile } = enterpriseProfileSlice.actions;

export default enterpriseProfileSlice.reducer;

import React, { useState } from 'react';
import './FAQsComponent.css';
import DeleteIcon from '../../assets/images/DeleteCourse.svg'
const FAQsComponent = ({ faqs, setFaqs }) => {
  const [newFaq, setNewFaq] = useState({ question: '', answer: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFaq((prevFaq) => ({
      ...prevFaq,
      [name]: value,
    }));
  };

  const handleAddFaq = (event) => {
    event.preventDefault(); // Prevent the default behavior
    if (newFaq.question.trim() && newFaq.answer.trim()) {
      setFaqs((prevFaqs) => [...prevFaqs, newFaq]);
      setNewFaq({ question: '', answer: '' });
    }
  };

  const handleRemoveFaq = (index) => {
    setFaqs((prevFaqs) => prevFaqs.filter((_, i) => i !== index));
  };

  return (
    <div className="faqs-container">
      <div className="InstructorCreateCourse_form_Section_title"> FAQs</div>
      <div className="faqs-form">
        <input
          type="text"
          name="question"
          placeholder="Enter question"
          value={newFaq.question}
          onChange={handleInputChange}
          className="faq-input"
        />
        <textarea
          name="answer"
          placeholder="Enter answer"
          value={newFaq.answer}
          onChange={handleInputChange}
          className="faq-textarea"
        />
        <div className='faq-component-container'>


           <button onClick={(e) => handleAddFaq(e)} className="faq-add-button">
              + Add New FAQs
            </button>

        </div>
       
      </div>
      <ul className="faqs-list">
        {faqs.map((faq, index) => (
          <li key={index} className="faq-item">
         <div>
  <strong>Question Item:</strong> 
  {faq.question.length > 100 
    ? `${faq.question.substring(0, 80)}...` 
    : faq.question}
  <br />
  {/* <strong>A:</strong> {faq.answer} */} 
</div>
         
            <img src={DeleteIcon} onClick={() => handleRemoveFaq(index)} className='fag-delete-icon'/>

          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQsComponent;

import React, { useEffect, useState, useRef } from 'react';
import Line from '../../assets/images/Line.svg';
import cameraIcon from '../../assets/images/CameraIcon.svg';
import UserImage from '../../assets/images/instructors/instructor1.png';
import { Link, useNavigate } from 'react-router-dom';
import './userProfile.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import { URLUser } from '../../config';
import UserNavbar from '../../component/UserNavbar/UserNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { userUpdateProfile } from '../../store/userFeature/userUpdateImageProfileSlice';
import { fetchUserData } from '../../store/userFeature/userDataSlice';
import { motion } from 'framer-motion'; // استيراد Framer Motion

const UserProfile = () => {
  const storedData = JSON.parse(localStorage.getItem("user_data"));
  const navigate = useNavigate(); // define navigate from react router dom

  // change profile picture and link the api by redux 
  const dispatch = useDispatch();
  const fileInputRef = useRef(null); // useRef hook 
  const [userImage, setUserImage] = useState(''); // State for the user's image

  // Fetching loading states from Redux
  const { loading: updateLoading, error: updateError } = useSelector((state) => state.userUpdateProfile);
  const { loading: userLoading, error: userError, userData } = useSelector((state) => state.userData);

  // UseEffect for fetching user data
  useEffect(() => {
    if (!userData) { // Only fetch if userData is not already available
      dispatch(fetchUserData());
    }
  }, [dispatch, userData]);

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create URL for new image
      setUserImage(imageUrl); // Update image in the state
      dispatch(userUpdateProfile(file)); // Upload image using Redux
    }
  };

  // Check if userData is defined before accessing properties
  const userImageSrc =  userData?.data?.user?.image ? userData?.data?.user?.image : UserImage;

  // Logout function
  const logout = async () => {
    const token = Cookies.get('authToken'); // test token 
    try {
      await axios.get(`${URLUser}/logout`, {
        headers: {
          token: `${token}`,
        },
      });
      // If logout is successful, remove the token and redirect to the login page
      Cookies.remove('authToken');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Loading states and errors
  if (updateLoading ) {
    return (
      <motion.div
      >
        <motion.div
          className="spinner"
          style={{
            width: '40px',
            height: '40px',
            //border: '8px solid #f3f3f3',
            borderTop: '8px solid #3498db',
            borderRadius: '50%',
          }}
          animate={{ rotate: 360 }}
          transition={{ duration: 1, ease: "linear", repeat: Infinity }}
        />
      </motion.div>
    );
  }

  if (updateError) return <p>Error updating image: {updateError}</p>;
  if (userError) return <p>Error fetching user data: {userError}</p>;

  return (
    <div>
      {/* Start User Profile Container */}
      <div className='UserPage_Continer'>
        {/* Start User Profile Header */}
        <div className='UserPage_Header'>
          <UserNavbar />
          <button className='Logout_button' onClick={logout}>Logout</button>
        </div>
        {/* Start User Profile Section 1 */}
        <div className='UserPage_Sec1'>
          <div className='UserPage_Sec1_Part1'></div>
          <div className='UserPage_Sec1_Part2'>
            {/* User Information Section */}
            <div className='UserPage_Sec2'>
              <div className='UserPage_Sec2_part2_name'>
                {storedData.first_name + ' ' + storedData.last_name}
              </div>

              <div className='UserPage_Sec2_Part1'>
                <img className='UserImage' src={userImageSrc} alt='UserImage' />
                <img
                  className="user_CameraIcon"
                  src={cameraIcon}
                  alt='Camera Icon'
                  onClick={() => fileInputRef.current.click()}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
              </div>
              <div className='UserPage_Sec2_part2'>
                <div style={{ display: 'flex', gap: '15px' }}>
                  <div className='UserPage_Sec2_part2_email'>
                    {storedData.email}
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Link to='/update-profile'><button className='Logout_button'>Update Profile</button></Link>
                </div>
              </div>
            </div>
            {/* END User Section 2 */}
            {/* Section 3: User Relations, Blog and Course in Profile */}
            <div className='UserPage_Sec3'>
              {/* Placeholder for saved courses and articles */}
            </div>
            {/* END Section 3 */}
          </div>
          <div className='Line'><img src={Line} alt='Line' /></div>
          <div className='UserPage_Sec5'></div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

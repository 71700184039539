import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // استيراد الـ URL من config

// تسجيل الـ Enterprise
export const registerEnterprise = createAsyncThunk(
  'enterprise/registerEnterprise',
  async (enterpriseData, { rejectWithValue }) => {
    try {
      // إرسال البيانات في الـ body من الـ request
      const response = await axios.post(`${URL}/enterprise/register`, enterpriseData); // لا حاجة لإرسال التوكن في الهيدر لأول مرة
      return response.data; // إرجاع البيانات في حالة النجاح
    } catch (err) {
      return rejectWithValue(err.response?.data || 'Error registering enterprise'); // التعامل مع الأخطاء
    }
  }
);

const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState: {
    enterpriseData: {}, // البيانات الخاصة بالـ enterprise
    status: 'idle', // الحالة الأولية
    error: null, // حالة الخطأ
    loading: false, // حالة الـ loading
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerEnterprise.pending, (state) => {
        state.status = 'loading'; // عندما يكون الطلب جارٍ
        state.error = null;
        state.loading = true; // تعيين الـ loading إلى true
      })
      .addCase(registerEnterprise.fulfilled, (state, action) => {
        state.status = 'succeeded'; // عندما ينجح الطلب
        state.enterpriseData = action.payload || {}; // تخزين بيانات الـ enterprise
        state.loading = false; // تعيين الـ loading إلى false
      })
      .addCase(registerEnterprise.rejected, (state, action) => {
        state.status = 'failed'; // عندما يفشل الطلب
        state.error = action.payload || 'Failed to register enterprise'; // تخزين رسالة الخطأ
        state.loading = false; // تعيين الـ loading إلى false
      });
  },
});

export default enterpriseSlice.reducer;

import React, { useState } from 'react';
import DeleteIcon from '../../assets/images/DeleteCourse.svg';

const ContentComponent = ({ contentData, setContentData }) => {
  const [newContent, setNewContent] = useState({ title: '', details: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContent((prevContent) => ({
      ...prevContent,
      [name]: value,
    }));
  };

  const handleAddContent = (event) => {
    event.preventDefault(); // Prevent default behavior
    if (newContent.title.trim() && newContent.details.trim()) {
      setContentData((prevContent) => [...prevContent, newContent]);
      setNewContent({ title: '', details: '' });
    }
  };

  const handleRemoveContent = (index) => {
    setContentData((prevContent) => prevContent.filter((_, i) => i !== index));
  };

  return (
    <div className="faqs-container">
      <div className="InstructorCreateCourse_form_Section_title"> Content Section</div>
      <div className="faqs-form">
        <input
          type="text"
          name="title"
          placeholder="Enter title"
          value={newContent.title}
          onChange={handleInputChange}
          className="faq-input"
        />
        <textarea
          name="details"
          placeholder="Enter details"
          value={newContent.details}
          onChange={handleInputChange}
          className="faq-textarea"
        />
        <div className="faq-component-container">
          <button onClick={(e) => handleAddContent(e)} className="faq-add-button">
            + Add New Content
          </button>
        </div>
      </div>
      <ul className="faqs-list">
        {contentData.map((content, index) => (
          <li key={index} className="faq-item">
            <div>
              <strong>Title:</strong>{' '}
              {content.title.length > 100 ? `${content.title.substring(0, 80)}...` : content.title}
              <br />
              <strong>Details:</strong> {content.details}
            </div>
            <img
              src={DeleteIcon}
              onClick={() => handleRemoveContent(index)}
              className="fag-delete-icon"
              alt="Delete"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContentComponent;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Thunk to unarchive an article
export const unarchiveArticle = createAsyncThunk(
  'article/unarchive',
  async ({ article_id }, { rejectWithValue }) => {
    const token = Cookies.get('Instructor_Token'); // Fetch token from cookies
    if (!token) {
      return rejectWithValue('Token is missing');
    }
    try {
      const response = await axios.get(
        `${URL}/articles/${article_id}/unarchive`,
        {
          headers: {
            token: token, // Add token in headers
          },
        }
      );
      console.log('API Response for Unarchive:', response.data);
      return { article_id, message: response.data.message }; // Adjust based on the actual API response structure
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const unarchiveArticleSlice = createSlice({
  name: 'unarchiveArticle',
  initialState: {
    status: null,
    error: null,
    unarchivedArticles: [], // Optionally store unarchived article IDs
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(unarchiveArticle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(unarchiveArticle.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Remove the article ID from the archived list or mark as unarchived
        state.unarchivedArticles.push(action.payload.article_id); // Store unarchived article ID
      })
      .addCase(unarchiveArticle.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.msg || 'Failed to unarchive the article.';
      });
  },
});

export default unarchiveArticleSlice.reducer;

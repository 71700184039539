import React from 'react';
import DOMPurify from 'dompurify';

const EnterpriseAbout = ({ EnterpriseAbout }) => {
  // Sanitize the HTML before rendering
  const sanitizedHTML = DOMPurify.sanitize(EnterpriseAbout);

  return (
    <div style={{display:'flex', justifyContent:'center',alignItems:'center', maxWidth:'80%'}}>
       <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </div>
  );
};

export default EnterpriseAbout;

import React, { useState } from "react";
import { Link , useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PasswordInput from "../../component/PasswordInput/PasswordInput";
import CityDropdown from "./CityID";
import { useLanguage } from "../../context/LanguageContext";
import { registerEnterprise } from "../../store/enterpriseFeature/registerEnterpriseSlice";





const EnterpriseForm = () => {
  const { language } = useLanguage(); // Access the current language
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate(); // استخدام useNavigate للتنقل
  // Accessing Redux state for loading, error, and success
  const { status, error: reduxError, loading } = useSelector(state => state.registerenterprise); 




  const handleCityChange = (cityId) => setSelectedCity(cityId);
  const handleCompanyNameChange = (e) => setCompanyName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleRePasswordChange = (e) => setRePassword(e.target.value);


  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (password !== rePassword) {
      setError(language === "en" ? "Passwords do not match" : "كلمات المرور غير متطابقة");
      return;
    }
    
    setError("");
  
    const formData = new FormData();
    formData.append("name", companyName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("city_id", selectedCity);
    dispatch(registerEnterprise(formData)).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          // الانتظار ثانيتين قبل التنقل
          setTimeout(() => {
             // passing data between pages using { navigate , useLocation }
            navigate("/Enterprise-Verfication-Email" , {state : {email : email }}); // استبدل "/Enterprise-Verfication-Email" بالرابط المطلوب
          }, 2000);
        }
      });
  };
  

  // Multilingual text
  const texts = {
    en: {
      enterpriseName: "Enterprise Name",
      enterpriseEmail: "Enterprise Email",
      enterprisePhone: "Enterprise Phone",
      password: "Password",
      rePassword: "Re-enter Password",
      city: "City",
      signup: "Signup",
      alreadyHaveAccount: "Already have an account?",
      login: "Log In",
    },
    ar: {
      enterpriseName: "اسم الشركة",
      enterpriseEmail: "البريد الإلكتروني للشركة",
      enterprisePhone: "رقم هاتف الشركة",
      password: "كلمة المرور",
      rePassword: "أعد إدخال كلمة المرور",
      city: "المدينة",
      signup: "تسجيل",
      alreadyHaveAccount: "هل لديك حساب بالفعل؟",
      login: "تسجيل الدخول",
    },
  };

  const t = texts[language]; // Select texts based on the current language

  return (
    <form  className={`EnterpriseForm ${language === "ar" ? "rtl" : "ltr"}`} 
     onSubmit={handleSubmit}
     dir={language === "ar" ? "rtl" : "ltr"}
     >
      <div className="Signup_body_Form_Sec4">
        <div>{t.enterpriseName}</div>
        <div className="Signup_body_input">
          <input
            className="Sign_input"
            type="text"
            placeholder={t.enterpriseName}
            value={companyName}
            onChange={handleCompanyNameChange}
            required 
                     />
        </div>
      </div>

      <div className="Signup_body_Form_Sec4">
        <div>{t.enterpriseEmail}</div>
        <div className="Signup_body_input">
          <input
            className="Sign_input"
            type="email"
            placeholder={t.enterpriseEmail}
            value={email}
            onChange={handleEmailChange}
            required 
          />
        </div>
      </div>

      <div className="Signup_body_Form_Sec4">
        <div>{t.enterprisePhone}</div>
        <div className="Signup_body_input">
          <input
            className="Sign_input"
            type="text"
            placeholder={t.enterprisePhone}
            value={phone}
            onChange={handlePhoneChange}
            
          />
        </div>
      </div>

      <div className="Signup_body_Form_Sec4">
        <div>{t.password}</div>
        <div className="Signup_body_input">
          <PasswordInput
            className="Sign_input"
            type="password"
            placeholder={t.password}
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
      </div>

      <div className="Signup_body_Form_Sec4">
        <div>{t.rePassword}</div>
        <div className="Signup_body_input">
          <PasswordInput
            className="Sign_input"
            type="password"
            placeholder={t.rePassword}
            value={rePassword}
            onChange={handleRePasswordChange}
          />
        </div>
      </div>

      <div className="Signup_body_Form_Sec4">
        <div>{t.city}</div>
        <div className="Signup_body_input_city">
          <CityDropdown
            selectedCity={selectedCity}
            onCityChange={handleCityChange}
            language={language}
          />
        </div>
      </div>

      {error && <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>}

      <div className="Registration_body_Form_Sec5" style={{ marginTop: "25px" }}>
        <button type="submit" className="Signup_body_Form_Sec5_button">
        {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">{t.signup}...</span>
            </div>
          ) : (
            t.signup
          )}
        </button>
        <div>
          {t.alreadyHaveAccount}{" "}
          <Link to="/login" className="link">
            <span>{t.login}</span>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default EnterpriseForm;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie'; // استخدام Cookies لتخزين التوكن
import { URL } from '../../config'; // رابط الـ API

// Async thunk for deactivating user
export const deactivateUser = createAsyncThunk(
  'enterprise/deactivateUser',
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get('entoken'); // الحصول على التوكن من الكوكيز
      const response = await axios.post(
        `${URL}/enterprise/deactivate-user`, 
        {
          headers: {
            token: token, // إضافة التوكن في الهيدر
          },
        }
      );
      return response.data; // إعادة البيانات في حالة النجاح
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Unexpected error occurred'); // التعامل مع الخطأ
    }
  }
);

// Slice
const deactivateUserSlice = createSlice({
  name: 'deactivateUser',
  initialState: {
    loading: false,
    success: false,
    error: null,
    message: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deactivateUser.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload?.msg || 'User deactivated successfully';
      })
      .addCase(deactivateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to deactivate user';
      });
  },
});

export default deactivateUserSlice.reducer;

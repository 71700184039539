import React , { useState } from 'react'
import UserProfile from './UserProfile';
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar'
import Signup from '../../component/Signup/Signup';
import Login from '../../component/Login/Login';
import Footer from '../../component/Footer/Footer';
import UserNavbar from '../../component/UserNavbar/UserNavbar';
import Sidebar from '../../component/Sidebar';
const IndexUser = () => {
    const [showSignup, setShowSignup] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
  return (
    <div>
      {/* 
    <UnauthAppbar showSignup={showSignup} setShowSignup={setShowSignup}
    showLogin={showLogin}
    setShowLogin={setShowLogin}
    />
    */}
    {/* Use showSignup and setShowSignup as needed */}
      <div>
       <Sidebar/>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
       <div style={{width:'80%',marginLeft:'260px'}}><UserProfile /></div> 
        </div>
      </div>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'80%',marginLeft:'260px'}}>
       <Footer />
      </div>
    </div>
  )
}

export default IndexUser
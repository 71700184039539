import React from "react";
import YouTube from "react-youtube";

const YouTubePlayer = ({ videoUrl }) => {
    const extractVideoId = (url) => {
        const urlObj = new URL(url);
        return urlObj.searchParams.get("v");
    };

    const videoId = extractVideoId(videoUrl);

    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            autoplay: 0, // Set to 1 to autoplay the video
        },
    };

    const onPlayerReady = (event) => {
        console.log("Player is ready");
    };

    if (!videoId) {
        return <div>Invalid video URL</div>; // التعامل مع روابط غير صحيحة
    }

    return <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />;
};

export default YouTubePlayer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Thunk to archive an article
export const archiveArticle = createAsyncThunk(
  'article/archive',
  async ({ article_id }, { rejectWithValue }) => {
    const token = Cookies.get('Instructor_Token'); // Fetch token from cookies
    if (!token) {
      return rejectWithValue('Token is missing');
    }
    try {
      const response = await axios.get(
        `${URL}/articles/${article_id}/archive`,
        {
          headers: {
            token: token, // Add token in headers
          },
        }
      );
      console.log('API Response for Archive:', response.data);
      return { article_id, message: response.data.message }; // Adjust based on the actual API response structure
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const archiveArticleSlice = createSlice({
  name: 'archiveArticle',
  initialState: {
    status: null,
    error: null,
    archivedArticles: [], // Optionally store archived article IDs
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(archiveArticle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(archiveArticle.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.archivedArticles.push(action.payload.article_id); // Store archived article ID
      })
      .addCase(archiveArticle.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.msg || 'Failed to archive the article.';
      });
  },
});

export default archiveArticleSlice.reducer;

import React, { useState, useEffect } from 'react';
import DeleteCorse from '../../assets/images/DeleteCourse.svg';
import updatecourse from '../../assets/images/editcourse.svg';
import './ArticleDashboard.css';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorArticles } from '../../store/features/instructorArticleIdSlice'; // Import the action
import { archiveArticle } from '../../store/features/ArticleArchiveSlice';
import { unarchiveArticle  } from '../../store/features/ArticleUnArchiveSlice'; // Import the unarchive action
import { motion } from 'framer-motion';
import DOMPurify from 'dompurify'; // library for secure the input from xss attack 
const ArticleDashboard = ({ArchiveValue}) => {
  // State to manage the archived status for each article
  const [archivedArticles, setArchivedArticles] = useState([]);

  const [loadingArticleId, setLoadingArticleId] = useState(null); // State to track which article is loading
  const navigate = useNavigate();

  // Function to toggle archive status for a specific article


  // Link this component with redux-toolkit
  const dispatch = useDispatch();
  const { articles, status, error } = useSelector((state) => state.instructorArticle);

  console.log('Redux State:', { articles, status, error });
   // Fetch articles when the component is mounted
  useEffect(() => {
    dispatch(fetchInstructorArticles({ is_archived: ArchiveValue }));
  }, [dispatch]);

   // Archive Article data  
   const handleArchiveClick = (article_id) => {
    setLoadingArticleId(article_id); // Set loading state when archiving starts
    dispatch(archiveArticle({ article_id }))
      .then((action) => {
        setLoadingArticleId(null); // Reset loading state after archiving is done
        //window.location.reload(); // Reload the page
        navigate('/instructor-home', { state: { activeSection: 'Archive' } }); // الانتقال إلى قسم الأرشيف
      })
      .catch((error) => {
        setLoadingArticleId(null); // Reset loading state on error
      });
  };


    // Dispatch action to unarchive the article
    const handleUnarchiveClick = (article_id) => {
      setLoadingArticleId(article_id); // Set loading state for this article
      dispatch(unarchiveArticle({ article_id }))
        .then(() => {
          // After successful unarchive, update the state
          setArchivedArticles(archivedArticles.filter((id) => id !== article_id));
          navigate('/instructor-home', { state: { activeSection: 'Articles' } }); // الانتقال إلى قسم المقالات
        })
        .finally(() => {
          setLoadingArticleId(null); // Reset loading state
        });
    };

  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'failed') {
    return <p>Error: {error}</p>;
  }

  const truncate = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  };

  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
  };

  const handleUpdateClick = (id) => {
    navigate(`/update-article/${id}`);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
       {articles.length === 0 ?        <motion.div
          initial={{ opacity: 0, y: -80 }} // حالة البداية
          animate={{ opacity: 1, y: 5 }} // حالة التفعيل
          exit={{ opacity: 0, y: 20 }} // حالة الخروج
          transition={{ duration: 1.5 }} // مدة الحركة
          style={{display:'flex' , justifyContent:'center' , flexDirection:'column', textAlign: 'center', fontSize: '20px', margin: '20px 0' }}
        >
          <p>No Articles are currently available.</p>
          <p>Feel free to Add New Now !</p>
        </motion.div> : ''}
      {articles.map((article) => (
        <div
          key={article.id}
          className={`${archivedArticles.includes(article.id) ? 'Unarchived_Article' : 'Article_container_Dashboard'}`}
        >
          <div className='Article_Sec1'>
            <img className='Article_Sec1' src={article.image} alt='ArticleImg' />
          </div>
          <div className='Article_Sec2'>5-10 mins to read</div>
          <div className='Article_Sec3' onClick={() => handleArticleClick(article.id)}>
            {truncate(article.title, 30)}
          </div>
          <div className='Article_Sec4' onClick={() => handleArticleClick(article.id)}>
          <div style={{maxWidth:'250px'}}
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(truncate(article.description, 50) + ' readmore'),
       }}
         />
          </div>
          <div className='Article_Sec5'>
            <div className='Author_information'>
              <div className='Article_Sec5_Part1'>
                <img className='author_icon' src={article.instructor.profile_image} alt='AuthorImage' />
              </div>
              <div className='Article_Sec5_Part2'>
                <div className='Article_Sec5_Part2_name' onClick={() => handleArticleClick(article.id)}>
                  {article.instructor.name}
                </div>
                <div className='Article_Sec5_Part2_date' onClick={() => handleArticleClick(article.id)}>
                  {article.publish_date}
                </div>
              </div>
            </div>
          </div>
          <div className='Course_Sec7'>
             {ArchiveValue === 0 ? 
                <button
                     className='Course_Dashboard_Archive'
                     onClick={() => handleArchiveClick(article.id)}
                     disabled={loadingArticleId === article.id} // Disable the button while loading
                 >
                   {loadingArticleId === article.id ?  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : (archivedArticles.includes(article.id) ? 'UNArchive' : 'Archive')}
                </button>
                 
                  : 
                  <button
                  className='Course_Dashboard_Archive'
                  onClick={() => handleUnarchiveClick(article.id)}
                  disabled={loadingArticleId === article.id} // Disable the button while loading
                >
                  {loadingArticleId === article.id ?  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'UNArchive'}
                </button>
            
            
            }




       
            <div className='course_icon_modify'>
              <img src={updatecourse} alt='updateCourse' style={{cursor:'pointer'}} onClick={() => handleUpdateClick(article.id)} />
              <img src={DeleteCorse} alt='deleteCourse' />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArticleDashboard;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config'; // Adjust the import to match your project structure

// Async thunk to archive a course
export const archiveEnterpriseCourse = createAsyncThunk(
  'enterpriseCourses/archiveEnterpriseCourse',
  async (courseId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('entoken'); // Fetch token from cookies
      const response = await axios.get(
        `${URL}/enterprise/courses/${courseId}/archive`,
        {
          headers: {
            token, // Add the token in headers
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      // Handle and return error
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

const archiveEnterpriseCourseSlice = createSlice({
  name: 'archiveEnterpriseCourse',
  initialState: {
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    successMessage: null, // Store success messages if needed
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(archiveEnterpriseCourse.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.successMessage = null;
      })
      .addCase(archiveEnterpriseCourse.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.successMessage = action.payload.message; // Use success message from the response
      })
      .addCase(archiveEnterpriseCourse.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default archiveEnterpriseCourseSlice.reducer;

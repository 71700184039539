// File: src/store/features/checkForgetCodeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';

// Async thunk to check OTP code
export const checkForgetCode = createAsyncThunk(
  'enterprise/checkForgetCode',
  async ( otp_code , { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/enterprise/check-forget-code`, { otp_code });
      return response.data; // Response expected to have { status, msg, data }
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { status: false, msg: 'Something went wrong' }
      );
    }
  }
);

// Slice for managing check-forget-code state
const checkForgetCodeSlice = createSlice({
  name: 'checkForgetCode',
  initialState: {
    loading: false, // Indicates API call is in progress
    success: null, // Success message from server response
    error: null, // Error message from server response
  },
  reducers: {
    // Reset state action for cleanup
    resetCheckCodeState: (state) => {
      state.loading = false;
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Pending state (API call started)
      .addCase(checkForgetCode.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
      })
      // Fulfilled state (API call succeeded)
      .addCase(checkForgetCode.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status) {
          state.success = action.payload.msg; // Use success message from response
          state.error = null;
        } else {
          state.success = null;
          state.error = action.payload.msg; // Use error message from response
        }
      })
      // Rejected state (API call failed)
      .addCase(checkForgetCode.rejected, (state, action) => {
        state.loading = false;
        state.success = null;
        state.error = action.payload?.msg || 'An unexpected error occurred';
      });
  },
});

// Export the reset state action
export const { resetCheckCodeState } = checkForgetCodeSlice.actions;

// Export the reducer to be added to the store
export default checkForgetCodeSlice.reducer;

// InstructorCreateCourse.jsx
import React, { useState, useRef, useCallback, useEffect } from 'react';
import './instructorcreateCourse.css';
import LineDashboard from '../../../assets/images/instructors/LineDashboard.svg';
import VolumeIcon from '../../../assets/images/Course/VolumeLevel.svg';
import UploadIcon from '../../../assets/images/lmg_upload.svg';
import ExitIcon from '../../../assets/images/instructors/exitIcon.svg';
import { useDispatch , useSelector } from 'react-redux';
import { storeCourse } from '../../../store/features/instructorStoreCourseSlice';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../../config';
import CourseLevelSelector from './CourseLevelSelector';
import CategoryDropdown from './Category';
import CityDropdown from './City';
import TimeSessionSelector from './TimeSessionSelector'
import FAQsComponent from '../../../component/FAQSComponent/FAQsComponent';
import ContentComponent from '../../../component/ContentComponent/ContentComponent';
import MilestoneData from '../../../component/MilestoneDataComponent/MilestoneData'; // Adjust the import path.
import TimeComponent from '../../../component/TimeComponent/TimeCourse';
import { useNavigate } from 'react-router-dom';
const libraries = ["places"]; // لاستخدام المكتبة العادية بدون advanced markers
const InstructorCreateCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log(typeof storeCourse); // Should log 'function'
  const { loading, error ,successMessage } = useSelector((state) => state.instructorStoreCourse);
  // select level Id
  const [selectedLevel, setSelectedLevel] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [times, setTimes] = useState([]);
  const handleLevelChange = (levelId) => {
    setSelectedLevel(levelId);

  };
   // select category by id 
   const [selectedCategory, setSelectedCategory] = useState('');
    console.log('test category ID : ' , selectedCategory)
   const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  // Select City By Id 
  const [selectedCity, setSelectedCity] = useState('');

   const handleCityChange = (cityId) => {
     setSelectedCity(cityId);
    };

  // Time | Duration
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(30);
  const [sessions, setSessions] = useState(3);


  // map and define lat and lng 
  const [markerPosition, setMarkerPosition] = useState({ lat: 30.033333, lng: 31.233334 });
  const mapRef = useRef(null);
  console.log("Test Map : ",markerPosition.lng)
  const onLoad = useCallback((map) => {
    mapRef.current = map;


  // إنشاء Marker عادي قابل للسحب
  const marker = new window.google.maps.Marker({
    position: markerPosition,
    map: mapRef.current,
    draggable: true,
  });

    // إضافة Listener لتحديث الإحداثيات بعد السحب
    marker.addListener('dragend', (event) => {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
      console.log("New Position:", newPosition );
    });
  }, [markerPosition]);


  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
 

  const [formValues, setFormValues] = useState({
    category_id: selectedCategory,
    level_id: selectedLevel,
    city_id: selectedCity,
    title: '',
    type : '',
    promoVideoLink: '',
    description: '',
    content: '',
    has_certicate: '',
    courseLanguage: '',
    price: '',
    session_count: sessions, // initialize with sessions
    seats_count: '',
    course_hours: hours, // initialize with hours
    numSets: '',
    certified: null,
    lat: markerPosition.lat,
    lng: markerPosition.lng,
   /* faqs: [{ question: 'test', answer: 'test' }],*/
  });

  
   console.log('formValues.type',formValues.type ,formValues , )
   console.log("Content Data:", contentData);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setImagePreviewUrl(URL.createObjectURL(file)); // Create a URL for the selected file
  };

  // Sync formValues.course_hours with hours from TimeSessionSelector
  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      course_hours: hours,
    }));
  }, [hours]);

  // Sync formValues.session_count with sessions from TimeSessionSelector
  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      session_count: sessions,
    }));
  }, [sessions]);
 
    // Synchronize category_id with selectedCategory
    useEffect(() => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        category_id: selectedCategory,
      }));
    }, [selectedCategory]);
  
    // Synchronize city_id with selectedCity
    useEffect(() => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        city_id: selectedCity,
        level_id :selectedLevel
      }));
    }, [selectedCity , selectedLevel]);


    // Update formValues.lat and formValues.lng whenever markerPosition changes
useEffect(() => {
  setFormValues((prevFormValues) => ({
    ...prevFormValues,
    lat: markerPosition.lat,
    lng: markerPosition.lng,
  }));
}, [markerPosition]);

  const handleImageClick = () => fileInputRef.current.click();


  const handleSubmit = async (e) => {
    e.preventDefault();

      // Basic validation
  if (!formValues.title || !formValues.description || !formValues.category_id || !selectedFile) {
    alert('Please fill out all required fields.');
    return;
  }

    const courseData = {
      ...formValues,
      image: selectedFile,
      faqs: faqs, // إضافة الأسئلة الشائعة هنا
      contentData : contentData , // Add Content About Course
      milestoneData : milestones , // add Ilos about course 
      times : times ,  // to add three time to start course 
    };

    const resultAction = await dispatch(storeCourse(courseData));

    if (storeCourse.rejected.match(resultAction)) {
      console.error("Error storing course:", resultAction.payload);
    } else {
      console.log("Course stored successfully:", resultAction.payload);
      navigate('/instructor-home', { state: { activeSection: 'Courses' } }); // الانتقال إلى قسم المقالات
    }
  };
  console.log('Test contentdata  ', contentData); // Optional: Log the selected level ID
  console.log('Test faqs  ', faqs); // Optional: Log the selected level ID
  console.log('Time  ', times); // Optional: Log the selected level ID
  return (
    <div> 
    <form onSubmit={handleSubmit} className="InstructorCreateCourse_Container">

          {/* Course Title */}
    <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Title <span className='Mandatory_star'>*</span></div>
        <input
          type="text"
          name="title"
          placeholder='Course name "Devops"'
          value={formValues.title}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
          required // Mark as required for browser validation
        />
      </div>

 {/*****************  Course Title  *******************/}
      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Description <span className='Mandatory_star'>*</span></div>
        <textarea
          rows="5"
          name="description"
          placeholder='Write a description about the course'
          value={formValues.description}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
          required // Mark as required for browser validation
        />
      </div>
         

    <div style={{display:'flex', width:'50%'}}>  
      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Category <span className='Mandatory_star'>*</span></div>
        <CategoryDropdown
          selectedCategory={selectedCategory}
          onCategoryChange={handleCategoryChange}
        />
      </div>


        <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Level <span className='Mandatory_star'>*</span></div>
          <CourseLevelSelector selectedLevel={selectedLevel} onLevelChange={handleLevelChange} />
        </div> 

     </div> 


     <div style={{display:'flex', width:'50%'}}>  
       <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Type Of Course <span className='Mandatory_star'>*</span></div>
        <select
           name="type"
           value={formValues.type}
           onChange={handleInputChange}
           required // Mark as required for browser validation
           className="city-select"
           style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
        >
             <option value="" disabled>
            Select type course
         </option>
          <option value="1">Offline</option>
          <option value="0">Online</option>
        </select>
      </div>


      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">City <span className='Mandatory_star'>*</span></div>
         <CityDropdown
           selectedCity={selectedCity}
           onCityChange={handleCityChange}
           language="en" // or 'ar' based on preference
           required // Mark as required for browser validation
         />
      </div>

   </div>

      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Promo Video Link <span className='Mandatory_star'>*</span></div>
        <textarea
          rows="5"
          name="promoVideoLink"
          value={formValues.promoVideoLink}
          onChange={handleInputChange}
          placeholder='https://www.youtube.com/watch?v=hWZRy3nKsJQ'
          className="InstructorCreateCourse_form_Section_input"
          required // Mark as required for browser validation
        />
      </div>
          {/*  Faqs component */}
     <div className="InstructorCreateCourse_form_Section">
      {/* Render the FAQsComponent and pass faqs & setFaqs */}
      <FAQsComponent faqs={faqs} setFaqs={setFaqs} />
      <ContentComponent contentData={contentData} setContentData={setContentData} />
      <MilestoneData milestones={milestones} setMilestones={setMilestones} />
      <div>
      <h1>Manage Times</h1>
      <TimeComponent times={times} setTimes={setTimes} />
     {/* <pre>{JSON.stringify(times, null, 2)}</pre> */} 
    </div>
     </div>

      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Content <span className='Mandatory_star'>*</span></div>
        <textarea
          rows="5"
          name="content"
          placeholder='please write a one word'
          value={formValues.content}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
          required // Mark as required for browser validation
        />
      </div>

                     <div className='InstructorCreateCourse_form_Section'>
                                <div className='InstructorCreateCourse_form_Section_title'>Duration <span className='Mandatory_star'>*</span></div> 
                                <TimeSessionSelector 
                                 hours={hours}
                                 setHours={setHours}
                                 minutes={minutes}
                                 setMinutes={setMinutes}
                                 sessions={sessions}
                                 setSessions={setSessions}
                                
                                />

                        </div>

      <div className="InstructorCreateCourse_form_Section">
  <div className="InstructorCreateCourse_form_Section_title">Has Certificate </div>
  <select
    name="has_certicate"
    value={formValues.has_certicate}
    onChange={handleInputChange}
    className="InstructorCreateCourse_form_Section_input"
  >

    <option value="1">Yes</option>
    <option value="0">No</option>
  </select>
</div>

<div className="InstructorCreateCourse_form_Section">
  <div className="InstructorCreateCourse_form_Section_title">
    Price <span className="Mandatory_star">*</span>
  </div>
  <input
    type="number"
    name="price"
    value={formValues.price}
    onChange={(e) => {
      const value = e.target.value;
      if (value >= 0) handleInputChange(e); // Allow only non-negative numbers
    }}
    onBlur={(e) => {
      if (!e.target.value) alert('Price is required'); // Alert for empty input
    }}
    className="InstructorCreateCourse_form_Section_input"
    required // Mark as required for browser validation
    min="0" // Prevents negative values using HTML5 validation
  />
</div>
<div className="InstructorCreateCourse_form_Section">
  <div className="InstructorCreateCourse_form_Section_title">
    Seats Count <span className="Mandatory_star">*</span>
  </div>
  <input
    type="number"
    name="seats_count"
    value={formValues.seats_count}
    onChange={(e) => {
      const value = e.target.value;
      if (value >= 0) handleInputChange(e); // Allow only non-negative numbers
    }}
    onBlur={(e) => {
      if (!e.target.value) alert('Seats Count is required'); // Alert for empty input
    }}
    className="InstructorCreateCourse_form_Section_input"
    required // Mark as required for browser validation
    min="0" // Prevents negative values using HTML5 validation
  />
</div>


  

      <div className="InstructorCreateCourse_form_Section">
          <div className="InstructorCreateCourse_form_Section_title">Select Location</div>
          <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
          <GoogleMap
           mapContainerStyle={{ height: "400px", width: "100%" }}
           center={markerPosition}
           zoom={10}
           onLoad={onLoad}
          />
          </LoadScript>
        </div>

     <div className="InstructorCreateCourse_form_Section">
      <div className="InstructorCreateCourse_form_Section_title">Upload Cover Photo <span className='Mandatory_star'>*</span></div>
      <div onClick={handleImageClick} style={{ cursor: 'pointer', position: 'relative', height: '300px', width: '100%', overflow: 'hidden' }}>
        <img src={UploadIcon} alt="Upload Icon" style={{ width: '50px', height: '50px', position: 'absolute', top: '10px', left: '10px' }} />
        {selectedFile && <p style={{ position: 'absolute', top: '70px', left: '10px', color: '#000' }}>{selectedFile.name}</p>}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />
        {imagePreviewUrl && ( // Show the selected image if available
          <img
            src={imagePreviewUrl}
            alt="Selected Cover"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Cover the entire container
          />
        )}
      </div>
    </div>
      
      <button type="submit" className="InstructorCreateCourse_Sec1_publish_Button">
        Submit Course
      </button>
         {/* Show loading spinner or message */}
         {loading && (
           <div className="loading-container">
           <div className="loading-spinner"></div>
           <p className="loading-text">Loading... Please wait.</p>
          </div>
          )}
         {/* Display error message */}
         {error && <p style={{ color: 'red', fontSize:'25px' }}> {error.response || error.msg || 'Failed to store course'}</p>}
         {successMessage && <div className="SuccessMessage">{successMessage}</div>}
    </form>
    </div>
  );
};

export default InstructorCreateCourse;

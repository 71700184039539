import React, { useState } from 'react';

import DeleteIcon from '../../assets/images/DeleteCourse.svg'; // Using the same delete icon

const MilestoneData = ({ milestones, setMilestones }) => {
  const [newMilestone, setNewMilestone] = useState({ title: '', details: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMilestone((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddMilestone = (event) => {
    event.preventDefault(); // Prevent default form behavior
    if (newMilestone.title.trim() && newMilestone.details.trim()) {
      setMilestones((prevMilestones) => [...prevMilestones, newMilestone]);
      setNewMilestone({ title: '', details: '' });
    }
  };

  const handleRemoveMilestone = (index) => {
    setMilestones((prevMilestones) => prevMilestones.filter((_, i) => i !== index));
  };

  return (
    <div className="faqs-container"> {/* Reusing the same container class */}
      <div className="InstructorCreateCourse_form_Section_title">ILos <span style={{fontSize:'18px'}}>( Intended Learning Outcomes )</span></div>
      <div className="faqs-form">
        <input
          type="text"
          name="title"
          placeholder="Enter milestone title"
          value={newMilestone.title}
          onChange={handleInputChange}
          className="faq-input"
        />
        <textarea
          name="details"
          placeholder="Enter milestone details"
          value={newMilestone.details}
          onChange={handleInputChange}
          className="faq-textarea"
        />
        <div className="faq-component-container">
          <button onClick={(e) => handleAddMilestone(e)} className="faq-add-button">
            + Add New Milestone
          </button>
        </div>
      </div>
      <ul className="faqs-list"> {/* Reusing the same list class */}
        {milestones.map((milestone, index) => (
          <li key={index} className="faq-item">
            <div>
              <strong>Title:</strong> {milestone.title.length > 100 
                ? `${milestone.title.substring(0, 80)}...` 
                : milestone.title}
              <br />
              <strong>Details:</strong> {milestone.details}
            </div>
            <img
              src={DeleteIcon}
              onClick={() => handleRemoveMilestone(index)}
              className="fag-delete-icon"
              alt="Delete"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MilestoneData;

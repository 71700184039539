import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Ensure this file contains the base API URL

// Async thunk for checking the forget code
export const instructorCheckForgetCode = createAsyncThunk(
  'instructor/checkForgetCode',
  async (otp_code, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/instructor/check-forget-code`, { otp_code });
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// Slice for checking the forget code
const instructorCheckForgetCodeSlice = createSlice({
  name: 'instructorCheckForgetCode',
  initialState: {
    loading: false,
    status: null,
    message: '',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(instructorCheckForgetCode.pending, (state) => {
        state.loading = true;
        state.status = null;
        state.message = '';
        state.error = null;
      })
      .addCase(instructorCheckForgetCode.fulfilled, (state, action) => {
        state.loading = false;
        state.status = true;
        state.message = action.payload.msg; // Use the success message from API
        state.error = null;
      })
      .addCase(instructorCheckForgetCode.rejected, (state, action) => {
        state.loading = false;
        state.status = false;
        state.message = '';
        state.error = action.payload || 'Something went wrong'; // Handle the error
      });
  },
});

export default instructorCheckForgetCodeSlice.reducer;

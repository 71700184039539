import React, { useState , useEffect }from 'react'
import './userNavbar.css'
import SearchIcon from '../../assets/images/search_icon.png'
import Arrowup from '../../assets/images/Arrow_up.png'
import Notfication from '../../assets/images/Notfication.svg'
import ProfileIcon from '../../assets/images/singleUserIcon.svg'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import NavbarCategory from '../Category/NavbarCategory'
import PolygonCategory from '../../assets/images/Polygon_Category.svg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../store/userFeature/userDataSlice'
import Select from 'react-select';



const UserNavbar = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [showNotifications, setShowNotifications] = useState(false); // New state to toggle notification dropdown
  const [showCategories, setShowCategories] = useState(false); // State to toggle NavbarCategory  =======     1 
  const storedData = JSON.parse(localStorage.getItem("user_data"));
  const ProfileSlug = storedData ? `${storedData.first_name}${storedData.last_name}` : '';
  const { slug } = useParams();  
  console.log(slug);
   const navigate = useNavigate() ;

  // Fetch user data when component mounts
  useEffect(() => {
    dispatch(fetchUserData()); // Fetch user data
  }, [dispatch]);



  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  //  console.log(searchText)
  }; 
  const handleSearch = () => {
      // Add your search functionality here
      console.log("Searching for:", searchText);
    };
  const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    };
    const handleIconClick = () => {
      handleSearch();
      handleKeyDown({ key: 'Enter' }); // Simulate Enter key press
    };
    
     const profileClick = ()=> {
      dispatch(fetchUserData)
      navigate(`/profile/${ProfileSlug}`);
     } 

     // about show category section  =========== 2 

     const toggleCategories = () => {
      setShowCategories((prev) => !prev); // Toggle the state
    };

      // Toggle notifications visibility
  const toggleNotifications = () => {
    setShowNotifications((prev) => !prev);
  };

    //  about user profile picture 
    const { loading: userLoading, error: userError, userData } = useSelector((state) => state.userData);

  
    // Check if userData is defined before accessing properties
    const userImageSrc = userData?.data?.user?.image ? userData?.data?.user?.image : userData?.data?.user?.image ;

    const notifications = [
      { value: '1', label: 'New comment on your post' },
      { value: '2', label: 'Your order has shipped' },
      { value: '3', label: 'New message from John' },
    ];

  
  return (
    <div>
    <div className='usernavbar_Container'>
         {/* About leftSide  [search and button category] */}
       <div className='usernavbar_Leftside'>
       <div className="search-container">
                  <input 
                  className="User_Search" 
                  type="text" 
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onClick={handleIconClick}
                  />
                  <img src={SearchIcon} alt="search icon" className="search-icon" onClick={handleIconClick}   />
             </div>


             <div className='categories-container'>
              <button className='UnauthAppbar_Second_app_button' onClick={toggleCategories} >Categories <img src={Arrowup} alt='arrowicon'></img></button>
              
              {showCategories && (

            <div className='navbarcategory-wrapper'>
              <NavbarCategory />
              <img src={PolygonCategory} alt='PolygonCategory' className='PolygonCategory'/>
            </div>
             )}
              
              </div>


       </div>
         {/* About right-side   [Notification and user profile ] */}
         <div className='usernavbar_Rightside'>
             <img src={Notfication}
              alt='notfication_icon'
              onClick={toggleNotifications} // Toggle notifications dropdown on click
              style={{ cursor: 'pointer' }}
              />
             {showNotifications && (
            <div className="notification-dropdown">
              <Select 
                options={Array.isArray(notifications) ? notifications : []} 
                placeholder='Notification'
                isSearchable={false} // Optional, you can enable search inside dropdown
              />
            </div>
          )}



             <img src={userImageSrc} alt="profile_icon" onClick={profileClick}  style={{width:'40px',height:'40px',borderRadius:'42px'}}/> 
         </div>

          
            
    </div>

    </div>
  )
}

export default UserNavbar
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk to store course data
export const storeCourse = createAsyncThunk(
  'instructor/storeCourse',
  async (courseData, { rejectWithValue }) => {
    try {
      // Get the instructor token from cookies
      const token = Cookies.get('Instructor_Token');

      // Make a POST request to store the course data
      const response = await axios.post(`${URL}/instructor/courses/store`, courseData, {
        headers: {
          token: token, // Use token as the header, consistent with previous components
          'Content-Type': 'multipart/form-data', // Handle form data for file uploads if needed
        },
      });

      console.log(response.data); // Check the received data
      return response.data.data; // Return the stored course data on success
    } catch (err) {
      console.log('error', err.response.data)
      return rejectWithValue(err.response ? err.response.data : 'An error occurred');
    }
  }
);

// Create a slice for course storage
const instructorStoreCourseSlice = createSlice({
  name: 'instructorStoreCourse',
  initialState: {
    course: null,    // Store the course data
    loading: false,  // Handle loading state
    error: null,     // Handle error state
    successMessage: null // Add success message property
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null; // Reset success message on pending state
      })
      .addCase(storeCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.course = action.payload; // Store the stored course data
        state.successMessage = 'Course stored successfully please wait to Confirm !'; // Set success message
      })
      .addCase(storeCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error if course storage fails
        state.successMessage = null;  // Clear success message on error
      });
  },
});

export default instructorStoreCourseSlice.reducer;

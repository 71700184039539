import React , {useEffect} from 'react'
import './NavbarCategory.css'
import LineCategory from '../../assets/images/Line_Category.svg'
import { useDispatch , useSelector } from 'react-redux'
import { fetchCategories } from '../../store/userFeature/userShowCategorySlice'
import Loading from '../Loading/Loading'

const NavbarCategory = () => {
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector((state) => state.categories);

    useEffect(() => {
        dispatch(fetchCategories());
      }, [dispatch]);


     /* if (loading) return <Loading />;*/
      if (error) return <p>Error: {error}</p>;
  return (
    <div className='Category_List_Container'>
        <div className='Category_List_Section_one'>
        <ul>
        {data.map((category) => (
          <li key={category.id}>{category.title}</li>
        ))}
      </ul>
        </div> 
             <img src={LineCategory} alt='LineCategory'/>
          {/* Static Section  */}   
        <div className='Category_List_Section_one'>
            <ul>
              <li>Top rated</li>
              <li>New courses</li>
              <li>Popular courses</li>
        
            </ul>
        </div> 





   </div>
  )
}

export default NavbarCategory
import React, { useState } from 'react';
import seeicon from '../../assets/images/seeicon.svg';
import unseeicon from '../../assets/images/unseeicon.svg';

const PasswordInput = ({ id, placeholder, value, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="PasswordInput">
      <input
        type={showPassword ? 'text' : 'password'}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required
      />
      <button
        type="button"
        className="ToggleVisibility"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <img src={seeicon} alt="seeicon" /> : <img src={unseeicon} alt="unseeicon" />}
      </button>
    </div>
  );
};

export default PasswordInput;

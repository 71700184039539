import React from 'react';
import './Error404.css'; // Import external CSS file

const Error404 = () => {
  return (
    <div className="error-page">
      <div className="error-content">
        <h1>404</h1>
        <h2>Oops! Page Not Found</h2>
        <p>
          Sorry, the page you're looking for doesn't exist. It might have been moved or deleted.
        </p>

      </div>
    </div>
  );
};

export default Error404;

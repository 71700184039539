// src/redux/slices/instructorOtpChangePasswordSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Adjust the import as necessary

// Async thunk to handle OTP-based change-password API call
export const instructorOtpChangePassword = createAsyncThunk(
  'instructor/otpChangePassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/instructor/change-password`, data);
      return response.data; // Return the response data (success or failure message)
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'An error occurred'
      );
    }
  }
);

// Slice for handling OTP-based change-password state
const instructorOtpChangePasswordSlice = createSlice({
  name: 'instructorOtpChangePassword',
  initialState: {
    status: null,  // To track success or failure
    message: '',   // To store the API response message
    loading: false, // To show loading state
    error: null,    // To handle errors
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(instructorOtpChangePassword.pending, (state) => {
        state.loading = true;
        state.status = null;
        state.message = '';
        state.error = null;
      })
      .addCase(instructorOtpChangePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.status = true;
        state.message = action.payload.msg;  // The success message from the API
        state.error = null;
      })
      .addCase(instructorOtpChangePassword.rejected, (state, action) => {
        state.loading = false;
        state.status = false;
        state.message = '';
        state.error = action.payload || 'Something went wrong'; // Error message
      });
  },
});

export default instructorOtpChangePasswordSlice.reducer;

import React , { useState } from 'react'
import { useEffect } from 'react'
import './instructorHome.css'
import EditInstructorIcon from '../../../assets/images/instructors/Icon-Edit.svg'
import PasswordIcon from '../../../assets/images/passwordIcon.svg'
//import Line from '../../../assets/images/Line.svg'
import InstructorCourses from './InstructorCourses'
import InstructorArticles from './instructorArticles'
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorProfile } from '../../../store/features/instructorGetProfileSlice'
import { updateProfileImage } from '../../../store/features/instructorProfilePictureSlice';
import Loading from '../../../component/Loading/Loading'
import AboutInstructor from './AboutInstructor'
import SaveIcon from '../../../assets/images/instructors/save.svg'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import Archive from './Archive/Archive'
import ChangeInstructorPassword from './ChangeInstructorPassword'
import CameraIcon from '../../../assets/images/CameraIcon.svg'
import { useLocation } from 'react-router-dom'

const InstructorHome = () => {

  const location = useLocation();
  // State to manage active section
  const [activeSection, setActiveSection] = useState(location.state?.activeSection || 'Courses');

// تأكد من تحديث القسم النشط إذا تم تغيير الحالة
useEffect(() => {
  if (location.state?.activeSection) {
    setActiveSection(location.state.activeSection);
  }
}, [location.state]);

     const navigate = useNavigate();
     // Data From Redux
     useEffect(() => {
      const token = Cookies.get('Instructor_Token');
      if (!token) {
        navigate('/login');
      }
    }, [navigate]);
     const dispatch = useDispatch();
     const { profile, loading, error } = useSelector((state) => state.instructorProfile);
     console.log("Profile data:", profile);

   //  const { status: imageStatus } = useSelector((state) => state.updateProfileImage);
     const [selectedImage, setSelectedImage] = useState(null);
     const [fileToUpload, setFileToUpload] = useState(null); // Store the selected file
    // console.log("profile: ",profile)
   // To active Section 4 Item 
  
   const renderSection = () => {
    switch (activeSection) {
      case 'Courses':
        return   <InstructorCourses /> ;
      
      case 'Articles':
        return <InstructorArticles/>;
      case 'About':
        return <AboutInstructor profile={profile} />;
      case 'Archive':
         return  <Archive /> ;   
      case 'password':
         return  <ChangeInstructorPassword /> ;   
      default:
        return null;
    }
  };
    // navigate to Edit Profile Instructor
  const handleEditInfoClick = () => {
    navigate('/Edit-information');
  };

  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFileToUpload(file); // Store the file for later uploading
    }
  };

// Handle the save action (upload the image)
const handleSaveImage = () => {
  if (fileToUpload) {
    const formData = new FormData();
    formData.append('profile_image', fileToUpload);

    dispatch(updateProfileImage(formData))
      .then(() => {
        // Optionally dispatch fetch profile action or reload page
        dispatch(fetchInstructorProfile()); // Ensure you have this action imported and available
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error updating image:', error);
      });
  }
};

   useEffect(() => {
     dispatch(fetchInstructorProfile());
   }, [dispatch]);
   
   if (loading) return <Loading />;
   if (error) return <p>Error: {error}</p>;
   if (!profile || !profile.instructor) {
    return <p>Loading or no data available</p>;
  }

  return (
      
    <div className='InstructorHome_Container'>
              {/* all page divided as Sections*/}  
              {/* Section One "Welcome Instructor" */}  
         <div className='InstructorHome_Section1'> 
            Welocme  {profile.instructor.first_name} !
            <div className='InstructorPage_Sec1_Part1'></div>
         </div>
        
           {/* Section Two select from instructor Profile  "Welcome Instructor" */}  

          <div className='InstructorHome_Sec2_Container'>
           <div style={{display:'flex',alignItems:'center',gap:'32px'}}>
           <div className='InstructorPage_Sec2_Part1'>
            {/* Clickable profile image for uploading new image */}
            <label htmlFor='upload-profile-image' style={{ cursor: 'pointer' }}>
              <img
                className='profile_image_instructor'
                src={selectedImage || profile.instructor.profile_image}
                alt='InstructorImage'
              />
              <img src={CameraIcon} alt='Cameraicon' className='CameraIcon'/>
            </label>
            <input
              type='file'
              id='upload-profile-image'
              style={{ display: 'none' }}
              onChange={handleImageChange}
              accept='image/*'
            />
          </div>



                      <div className='InstructorPage_Sec2_part2'>
                             <div>
                                 <div className='InstructorPage_Sec2_part2_name'>{profile.instructor.first_name} {profile.instructor.last_name}</div>
                                 <div className='InstructorPage_Sec2_part2_Major'>{profile.instructor.job_title}</div>
                                 <div className='InstructorPage_Sec2_part2_JoinDate'>Joined  {profile.instructor.created_at}</div>
                             </div>
                             <div className='InstructorPage_Sec2_part3_button_Container'>
                                <button className='InstructorPage_Sec2_part2_EditInfo'    onClick={handleEditInfoClick}><img src={EditInstructorIcon} alt='editIcon'/> Edit My Info</button>
                                <button className='InstructorPage_Sec2_part2_ChangePassword'    onClick={() => setActiveSection('password')}><img src={PasswordIcon} alt='PasswordIcon'/> Change password </button>
                             </div>
                            
                                  {/* Display Save button after an image is selected */}
                                  {fileToUpload && (
                                     <button className='InstructorPage_SaveButton' onClick={handleSaveImage}>
                                      <img src={SaveIcon} alt='saveicon'/> Save New Image
                                     </button>
                                      )}
                     </div>
                     </div>
                                 {/* Section 2 Right Side  */}
                              <div className='InstructorPage_Sec2_part2_RightSide'>
                              <div className='InstructorPage_Sec2_part2_RightSide_Email'>
                              {profile.instructor.linked_in ? (
    <a 
      href={profile.instructor.linked_in.startsWith("http") 
        ? profile.instructor.linked_in 
        : `mailto:${profile.instructor.linked_in}`} 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: 'inherit' }} // اختياري: لتنسيق الرابط بنفس تنسيق النص
    >
      {profile.instructor.linked_in}
    </a>
  ) : (
    "No LinkedIn or Email provided"
  )}
                              </div>
                              <div className='InstructorPage_Sec2_part2_RightNumbers'>
                                   <div className='InstructorPage_Sec2_part2_RightNumbers_Items'><span>{profile.instructor.courses_count}</span> <span>courses</span> </div>
                                   <div className='InstructorPage_Sec2_part2_RightNumbers_Items'><span>{profile.instructor.articles_count}</span> <span>Articles</span> </div>
                                   <div className='InstructorPage_Sec2_part2_RightNumbers_Items'><span>{profile.instructor.user_followers}</span> <span>students</span> </div>
                              </div>
                                </div>   
                                
                     </div> 
                       {/* Section 3 and 4  */}
                        {/*  Start Section 4 */}
                      <div className='InstructorHomePage_Sec4'>
                      <div className={activeSection === 'Courses' ? 'active' : '' }
                      onClick={() => setActiveSection('Courses')}
                      >Courses</div>
                      <div className={activeSection === 'Articles'? 'active' : '' }
                      onClick={() => setActiveSection('Articles')}
                      >Articles</div>
                      <div className={activeSection === 'About' ? 'active' : '' }
                      onClick={() => setActiveSection('About')}
                      >About</div>
                      <div className={activeSection === 'Review'? 'active' : '' }
                      onClick={() => setActiveSection('Review')}
                      >Review</div>
                        <div className={activeSection === 'Archive'? 'active' : '' }
                      onClick={() => setActiveSection('Archive')}
                      >Archive</div>
             </div>
          
     
             <div style={{display:'flex',justifyContent:'center',alignItems:'center',width :'100%'}}>  
               {renderSection()}
             </div>
  
    </div>
  )
}
export default InstructorHome
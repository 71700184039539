import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer/Footer";
import Logo from '../../assets/images/lndexlogo_svg.svg';
import { useLocation } from "react-router-dom";
import { validateCodeEnterprise } from "../../store/enterpriseFeature/validatecodeEnterpriseSlice";


const EnterpriseVerificationEmail = () => {
  const [inputs, setInputs] = useState(["", "", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(200); // Countdown timer
  const [isResendDisabled, setIsResendDisabled] = useState(true);



  const location = useLocation();
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access Redux state
  const email = location.state?.email; // Access the email from the state
  //console.log(email)

  const {loading , error , validationResult  } = useSelector((state) => state.enterpriseValidateCode)

  // Timer countdown effect
  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      setIsResendDisabled(false); // Enable resend after timer ends
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Handle input change
  const handleChange = (index, value) => {
    if (value.length > 1) return; // Prevent more than 1 digit
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    // Move to the next input if the user types
    if (value && index < 4) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Handle Backspace
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !inputs[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
     // التحقق إذا تم الضغط على مفتاح Enter
  if (e.key === "Enter") {
    handleSubmit(); // تنفيذ عملية الإرسال عند الضغط على Enter
  }
  };

  // Resend Code Handler
  const handleResend = () => {
    setInputs(["", "", "", "", ""]);
    setTimeLeft(200);
    setIsResendDisabled(true);
    console.log("Resending Verification Code...");
  };


  
  // Submit Code Handler
  const handleSubmit = () => {
    const code = inputs.join("");
    if (code.length === 5) {
      
      dispatch(validateCodeEnterprise({ email, otp_code: code }))
      .then(() => {

       // validationResult = true
        // Redirect on success if needed
       {/* if (validationResult?.status === "success") {
          navigate("/Enterprise-Information-form");
        }*/}
      })
      .catch(() => {
        // Handle any error
        console.error("OTP Validation Error:");
      });




    } else {
      alert("Please enter a 5-digit code.");
    }





  };


  
  return (
    <div className="Otp-Container-page">
      <div className="verification-container">
        <img src={Logo} alt="lndex Logo" />
        <div className="Otp-info-section">
          <p className="subtitle">Enter Verification Code</p>
          <p className="instruction">Please Check Your E-mail For Verification Code?</p>
        </div>

        {/* Code Input Fields */}
        <div className="code-inputs">
          {inputs.map((input, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={input}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(el) => (inputRefs.current[index] = el)}
              className="code-input"
            />
          ))}
        </div>

        {/* Timer & Resend */}
        <div className="timer">
          {timeLeft > 0 ? (
            <span className="time-left">{timeLeft} Sec</span>
          ) : (
            <button
              onClick={handleResend}
              disabled={isResendDisabled}
              className="resend-btn"
            >
              Resend Verification Code
            </button>
          )}
        </div>

        {/* Submit Button */}
        <div className="otp-sent-container">
          <button onClick={handleSubmit} className="send-btn" disabled={loading}>
            {loading ?      <>
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    <span className='sr-only'> Sending...</span>
                  </>
                   :
                    "Send"
                    }
          </button>
        </div>

       
{validationResult && (
  <p className='success-message'>{validationResult.msg}</p>
)}
  
{/* Display Error Message */}
{error && (
  <p className='ErrorMessage'>
    {typeof error === "string" ?"JSON.stringify(error)" : error.msg }
  </p>
)}
      </div>

      {/* Footer    <Footer /> */}
    
    </div>
  );
};

export default EnterpriseVerificationEmail;

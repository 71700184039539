// src/features/userFeature/otpVerificationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Ensure you import your URL configuration

// Async thunk for verifying the OTP code
export const verifyOtpCode = createAsyncThunk(
  'otpVerification/verifyOtpCode',
  async (otpCode, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/check-forget-code`, {
        otp_code: otpCode,
      });

      return response.data; // Adjust this if your response structure is different
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const otpVerificationSlice = createSlice({
  name: 'otpVerification',
  initialState: {
    isLoading: false,
    error: null,
    success: false,
  },
  reducers: {
    resetOtpVerification: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtpCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyOtpCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true; // Set to true on successful verification
        // You can also handle any additional success logic here
      })
      .addCase(verifyOtpCode.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; // Set the error message on failure
      });
  },
});

// Export actions and reducer
export const { resetOtpVerification } = otpVerificationSlice.actions;
export default otpVerificationSlice.reducer;

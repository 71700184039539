// CourseDeleteSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk to delete a course
export const deleteCourse = createAsyncThunk(
  'course/deleteCourse',
  async (courseId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('Instructor_Token'); // Get the token from cookies
      const response = await axios.get(`${URL}/instructor/courses/${courseId}/destroy`, {
        headers: {
          token: token, // Use token in headers
        },
      });

      // Assuming the response data has "status" and "msg"
      if (response.data.status) {
        return {
          message: response.data.msg, // Return the success message
        };
      } else {
        return rejectWithValue({
          message: response.data.msg, // Return error message if status is false
        });
      }
    } catch (err) {
      console.log('error', err.response?.data || err);
      return rejectWithValue({
        message: err.response?.data?.msg || 'An error occurred', // Return error message from response
      });
    }
  }
);

// Create a slice for course deletion
const courseDeleteSlice = createSlice({
  name: 'courseDelete',
  initialState: {
    loading: false,  // Handle loading state
    error: null,     // Handle error state
    message: '',     // Store success or error messages
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCourse.pending, (state) => {
        state.loading = true;   // Set loading to true on pending
        state.error = null;     // Clear previous error messages
        state.message = '';      // Clear previous messages
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.loading = false;   // Set loading to false on success
        state.message = action.payload.message; // Set success message
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        state.loading = false;   // Set loading to false on failure
        state.error = action.payload.message; // Store the error message
        state.message = action.payload.message; // Set error message
      });
  },
});

export default courseDeleteSlice.reducer;

// courseArchiveSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk to archive a course
export const archiveCourse = createAsyncThunk(
  'course/archiveCourse',
  async (courseId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('Instructor_Token');
      const response = await axios.get(`${URL}/instructor/courses/${courseId}/archive`, {
        headers: {
          token: token,
        },
      });
      
      // Assuming the response data has "status", "msg", and "data.course"
      if (response.data.status) {
        return {
          course: response.data.data.course,
          message: response.data.msg, // Use the msg for success message
        };
      } else {
        return rejectWithValue({
          message:  response.data.msg , // In case the status is false
        });
      }
    } catch (err) {
      console.log('error', err.response?.data || err);
      return rejectWithValue({
        message: err.response?.data?.msg || 'An error occurred', // Use error message from response
      });
    }
  }
);

// Create a slice for course archival
const courseArchiveSlice = createSlice({
  name: 'courseArchive',
  initialState: {
    archivedCourse: null, // Store the updated course
    loading: false,       // Handle loading state
    error: null,          // Handle error state
    message: '',          // Add message state to store success or error messages
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(archiveCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = ''; // Clear previous messages
      })
      .addCase(archiveCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.archivedCourse = action.payload.course; // Update the archived course
        state.message = action.payload.message; // Set success message
      })
      .addCase(archiveCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message; // Store the error message
        state.message = action.payload.message; // Set error message
      });
  },
});

export default courseArchiveSlice.reducer;

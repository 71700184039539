import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie'; // استيراد js-cookie لحفظ التوكن
import { URL } from '../../config'; // استيراد الـ URL

// تسجيل الدخول للـ Enterprise
export const loginEnterprise = createAsyncThunk(
  'enterprise/loginEnterprise',
  async (credentials, { rejectWithValue }) => {
    try {
      // إرسال بيانات تسجيل الدخول
      const response = await axios.post(`${URL}/enterprise/login`, credentials);
      // حفظ التوكن في الـ Cookies
      Cookies.set('entoken', response.data.data.enterprise.token);
      return response.data.data.enterprise; // إرجاع بيانات الـ enterprise
    } catch (err) {
      return rejectWithValue(err.response?.data || 'Error logging in enterprise'); // التعامل مع الأخطاء
    }
  }
);

const enterpriseLoginSlice = createSlice({
  name: 'enterpriseLogin',
  initialState: {
    enterpriseData: null, // بيانات المستخدم
    status: 'idle', // الحالة الأولية
    error: null, // الأخطاء
    loading: false, // حالة الـ loading
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginEnterprise.pending, (state) => {
        state.status = 'loading'; // عند بدء الطلب
        state.error = null;
        state.loading = true;
      })
      .addCase(loginEnterprise.fulfilled, (state, action) => {
        state.status = 'succeeded'; // عند نجاح الطلب
        state.enterpriseData = action.payload; // تخزين بيانات المستخدم
        state.loading = false;
      })
      .addCase(loginEnterprise.rejected, (state, action) => {
        state.status = 'failed'; // عند فشل الطلب
        state.error = action.payload; // تخزين رسالة الخطأ
        state.loading = false;
      });
  },
});

export default enterpriseLoginSlice.reducer; // تصدير الـ reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Import the URL from config

// Validate the OTP for the Enterprise
export const validateCodeEnterprise = createAsyncThunk(
  'enterprise/validateCodeEnterprise',
  async ({ email, otp_code }, { rejectWithValue }) => {
    try {
      // Send the OTP and email in the body of the request
      const response = await axios.post(`${URL}/enterprise/validate-otp`, { email, otp_code });
      console.log(response.data)
      return response.data; // Return data on success
    } catch (err) {
      return rejectWithValue(err.response?.data || 'Error validating OTP'); // Handle errors
    }
  }
);

const validateCodeEnterpriseSlice = createSlice({
  name: 'enterpriseValidateCode',
  initialState: {
    status: 'idle', // Initial state for status
    error: null, // To store error messages
    loading: false, // To handle loading state
    validationResult: null, // Store validation response
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateCodeEnterprise.pending, (state) => {
        state.status = 'loading'; // Set loading to true while request is pending
        state.error = null; // Clear previous errors
        state.loading = true; // Set loading to true
      })
      .addCase(validateCodeEnterprise.fulfilled, (state, action) => {
        state.status = 'succeeded'; // Set status to succeeded on success
        state.validationResult = action.payload || {}; // Store the validation result
        state.loading = false; // Set loading to false
      })
      .addCase(validateCodeEnterprise.rejected, (state, action) => {
        state.status = 'failed'; // Set status to failed on error
        state.error = action.payload || 'Failed to validate OTP'; // Store error message
        state.loading = false; // Set loading to false
      });
  },
});

export default validateCodeEnterpriseSlice.reducer;

import React, { useEffect, useState } from 'react';
import './Article.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Instructo from '../../assets/images/instructors/hakim.jpg';
import Loading from '../Loading/Loading';
import { URL } from '../../config';
import DOMPurify from 'dompurify';

const Article = ({ wrap }) => { // Accept `wrap` prop to control styling
  const [articles, setArticles] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate(); 
  const authToken = Cookies.get('authToken'); 

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${URL}/all-articles`, {
          headers: {
            token: authToken , // تأكد من إرسال التوكن
          },
        });
        if (response.data?.data?.articles) {
          setArticles(response.data.data.articles);
        } else {
          console.error('Unexpected API response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchArticles();
  }, [authToken]);

  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
  };

  const truncateDescription = (description, limit = 60) => {
    return description.length > limit ? description.slice(0, limit) + '...' : description;
  };

  const truncateTitle = (title, length = 25) => {
    return title.length <= length ? title : title.substring(0, length) + 'read more';
  };

  return (
    <div className='Article_container'>
      
      {loading ? ( 
        <Loading /> 
      ) : (
        <div className={wrap ? 'Article_flex_container_WithWrap' : 'Article_flex_container'}>
          {articles.map((article) => (
            <div 
              key={article.id} 
              className='Article_card' 
              onClick={() => handleArticleClick(article.id)}
            >
              <div className='Article_Sec1'>
                <img className='Article_Sec1' src={article.image} alt='ArticleImg' />
              </div>
              <div className='Article_Sec2'>5-10 mins to read</div>
              <div className='Article_Sec3'>
                {truncateTitle(article.title)}
              </div>
              <div className='Article_Sec4'>
                {/* Display the truncated description safely */}
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncateDescription(article.description)) }} />
                <span 
                  className="read-more" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleArticleClick(article.id);
                  }}
                >
                </span>
              </div>
              <div className='Article_Sec5'>
                <div className='Author_information'>
                  <div className='Article_Sec5_Part1'>
                    <img
                      className='author_icon'
                      src={article.instructor.profile_image || Instructo}
                      onClick={(e) => {
                        e.stopPropagation(); // لمنع تفعيل النقر على البطاقة بأكملها
                        navigate(`/instructor/${article.instructor.id}`); // الانتقال إلى صفحة المدرس
                      }} 
                      alt='AuthorImage'
                    />
                  </div>
                  <div className='Article_Sec5_Part2'>
                    <div className='Article_Sec5_Part2_name'
                         onClick={(e) => {
                         // e.stopPropagation(); // لمنع تفعيل النقر على البطاقة بأكملها
                          navigate(`/instructor/${article.instructor.id}`); // الانتقال إلى صفحة المدرس
                          console.log("instructor id : ",article.instructor.id)
                        }} 
                    
                    
                    >{article.instructor.name}</div>
                    <div className='Article_Sec5_Part2_date'>{new Date(article.publish_date).toLocaleDateString()}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Article;

import React, { useState } from 'react';
import LineDashboard from '../../../../assets/images/instructors/LineDashboard.svg';
import UploadIcon from '../../../../assets/images/lmg_upload.svg';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie to handle token
import './instructorCreateArticle.css';
import RichTextEditor from '../../../../component/RichTextEditor/RichTextEditor';
import { useNavigate } from 'react-router-dom';

const InstructorCreateArticle = () => {
  const navigate = useNavigate()
  const [selectedImage, setSelectedImage] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false); // حالة التحميل
  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', selectedImage);

    // Get the token from cookies
    const token = Cookies.get('Instructor_Token'); // Assuming the token is stored under 'authToken'

    try {
      const response = await axios.post('https://dash.lndex.io/api/create-articale', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': token , // Add token to the request headers
        },
      });
      console.log('Article created successfully', response.data);
       //setAlert(response.data.msg)
       navigate('/Instructor-Home')
      // Handle success (e.g., clear form, show a success message, etc.)
    } catch (error) {
      console.error('Error creating article:', error);
     // alert('please try agian something error !')
      // Handle error (e.g., show an error message)
    }finally {
      setLoading(false); // إيقاف التحميل
    }
  };

  return (
    <div>
      <div className='Create_Article_Container'>
        {/* The First Section and Publish Button */}
        <div className='InstructorCreateCourse_Sec1'>
          <div>Fill the info below to add new Article</div>
        </div>

        {/* The Form Section */}
        <form onSubmit={handleSubmit} className='Create_Article_Form_Add'>
          {/* Article Title */}
          <div className='InstructorCreateCourse_form_Section'>
            <div className='InstructorCreateCourse_form_Section_title'>Write title of your article <span style={{color:'red'}}>*</span></div>
            <input
              type='text'
              className='InstructorCreateCourse_form_Section_input'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          {/* Article Description */}
          <div className='InstructorCreateCourse_form_Section'>
            <div className='InstructorCreateCourse_form_Section_title'>Add Description for your article <span style={{color:'red'}}>*</span></div>
            <RichTextEditor
              value={description}
              className='InstructorEdit_form_Section_ReactQuill'
              onChange={setDescription}
              required
            />
          </div>

          <img src={LineDashboard} alt='LineDashboard' />

          {/* Cover Photo Upload */}
          <div className='upload-Container'>
            {selectedImage ? (
              <img src={URL.createObjectURL(selectedImage)} alt="Selected" className='image-preview' />
            ) : (
              <div className='placeholder'>
                <img src={UploadIcon} alt='uploadimage'/>
                <p className='text'>Upload Photo</p>
                <small className='subtext'>Upload From Your Device Any Photo</small>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              className='file-input'
              onChange={handleImageChange}
              required
            />
          </div>

          {/* Publish Button */}
          <div className='Publish_Article'>
          <button
            type='submit'
            className='InstructorCreateCourse_Sec1_publish_Button'
            disabled={loading} // تعطيل الزر أثناء التحميل
          >
            {loading ? 'Loading...' : 'Publish Article'} {/* عرض النص المناسب */}
          </button>
          </div>
        </form>
         <div className='Article_added_Alert'>
             Your Article Add Goood 
         </div>
          
      </div>
    </div>
  );
};

export default InstructorCreateArticle;

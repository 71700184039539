import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ value, onChange }) => {
  // إعدادات الموديلات الخاصة بـ ReactQuill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // خيارات الرأس
      [{ align: [] }], // خيارات المحاذاة
      [{ color: [] }], // خيارات الألوان
      ['bold', 'italic', 'underline'], // التنسيق
      ['image', 'code-block'], // الإدراج
      ['clean'], // زر تنظيف النص
    ],
  };

  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={modules}
      className="InstructorEdit_form_Section_ReactQuill"
      style={{ height: '200px' , maxWidth:'704px'}}
    />
  );
};

export default RichTextEditor;

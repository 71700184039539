import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './Notification.css'; // Add your CSS file for custom styles
import { LineUtil } from 'leaflet';
import { Link } from 'react-router-dom';

const NewUserNotification = ({ showNotification, onClose }) => {
  return (
    showNotification && (
      <motion.div
        className="notification-container"
        initial={{ opacity: 0, x: '100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ duration: 0.5 }}
      >
        <div className="notification-content">
          <span className="notification-message">
              

            You need to complete your profile <Link className='link' to='/enterprise/Information-form'>
            <div style={{backgroundColor:'#18172F', display:'flex', justifyContent:'center'}}>Go To Complete Info </div>
            </Link>
          </span>
          <button className="notification-close-button" onClick={onClose}>
            X
          </button>
        </div>
      </motion.div>
    )
  );
};

export default NewUserNotification;

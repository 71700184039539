import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '../../../../assets/images/instructors/add.svg';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import ArticleDashboard from '../../../../component/ArticleDashboard/ArticleDashboard';


const InstructorArticles = () => {
  return (
    <div className='Article_Show_component'>
      <div >
        <div>
        </div>
      </div>

      <div className='Courses_Instructor_Container'>
        {/* Iterate through the courses array to render CourseDashboard components dynamically */}
          <div>
            <div></div>
            <ArticleDashboard ArchiveValue={1}/>
          </div>
      
      </div>
    </div>
  );
};

export default InstructorArticles;

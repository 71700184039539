import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './Course.css';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import SavedCompact from '../../assets/images/Course/SavedCompact.svg';
import Saved from '../../assets/images/Course/CourseSaved.png';
import AddToCart from '../../assets/images/coursecard/shopping-cart-details.svg';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { URL } from '../../config';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import LeftArrow from '../../assets/images/leftarrow.png';
import RightArrow from '../../assets/images/rightarrow.png';

const EnterpriseCourse = () => {
  const token = Cookies.get('authToken'); // Retrieve the token from cookies
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(); // Reference to the course container
  const controls = useAnimation(); // Controls for the animation

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${URL}/enterprise/all-courses`);
        const data = await response.json();
        if (data.status) {
          setCourses(data.data.courses);
        } else {
          setError(data.msg || 'Failed to load courses');
        }
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);



  console.log(courses)

  const handleCourseClick = (id) => {
    navigate(`/enterprise/coursedetails/${id}`);
  };

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  const handleAddToCartClick = (courseId) => {
    if (!token) {
      setPopupMessage('Please log in to save courses to your cart.');
      setShowPopup(true);
      return;
    }
  };

  const handleSaveCourseClick = (courseId) => {
    if (!token) {
      setPopupMessage('Please log in to save courses.');
      setShowPopup(true);
      return;
    }
  };

  const scrollLeft = () => {
    controls.start({ x: 0, transition: { duration: 0.8, type: 'spring' } });
    scrollContainerRef.current.scrollBy({ left: 1000, behavior: 'smooth' });
  };

  const scrollRight = () => {
    controls.start({ x: -500, transition: { duration: 0.8, type: 'spring' } });
    scrollContainerRef.current.scrollBy({ left: 50, behavior: 'smooth' });
  };

  if (loading) return <Loading />;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <div style={{display:'flex', justifyContent:'space-between', width:'50%'}}>
      
        <div className="Home_Sec3_Part2">
          <img src={LeftArrow} alt="ArrowLeft" onClick={scrollLeft} />
          <div>Enterprise Courses</div>
          <img src={RightArrow} alt="ArrowRight" onClick={scrollRight} />
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        className="EnterpriseCourse"
        style={{ display: 'flex', gap: '16px', overflowX: 'auto', scrollBehavior: 'smooth',paddingTop:'50px',paddingBottom:'50px',overflow: 'hidden',  }}
      >
        {courses.map((course) => (
          <motion.div
            animate={controls}
            className="Course_Container"
            key={course.id}
            onClick={() => handleCourseClick(course.id)}
            whileHover={{ scale: 1 }}
          >
            <div>
              <div className="Course_Sec1">
                <img
                  className="Course_Sec1_image"
                  src={course.image}
                  alt={course.title}
                  style={{height:'35px'}}
                />
              </div>

              <div className="Course_Sec2">Start {course?.times[0].date}</div>
              <div className="Course_Sec3">{course.title}</div>
              <div className="Course_Sec4">
                <img src={Clock} alt="Clock" /> {course.course_hours} &nbsp; (
                {course.session_count} sessions)
              </div>
              <div className="Course_Sec5">
                <img src={Chair} alt="Chair" />
                {course.seats_count} seats left
                <img src={Line} alt="Line" />
                <img src={Start} alt="Start" />
                {course.rate || '95%'} (234)
              </div>
              <div
                style={{ paddingTop: '8px', color: 'blue' }}
                className="Course_Sec6"
              >
                {course.category.title}
              </div>
              <div className="Course_Sec6">{course.instructor?.name || 'N/A'}</div>
            </div>
            <div className="Course_Sec7_Home_guest">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveCourseClick(course.id);
                }}
              >
                <img src={Saved} alt="Save" />
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddToCartClick(course.id);
                }}
              >
                <img src={AddToCart} alt="addtocart_icon" />
              </div>
            </div>
          </motion.div>
        ))}

        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <div>
                <span className="close" onClick={closePopup}>
                  &times;
                </span>
              </div>
              <div>
                <p className="Popup_Message">{popupMessage}</p>
              </div>

              {!token ? (
                <div className="popup_button_container">
                  {/* Render Login and Signup buttons only if the user is not authenticated */}

                  <Link className="link" to="/Login">
                    <div>
                      <button className="popup_button_Login">Login</button>
                    </div>
                  </Link>
                  <Link className="link" to="/Signup">
                    <div>
                      <button className="popup_button_Signup">Sign Up</button>
                    </div>
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EnterpriseCourse;
